// Font size
.h-fz-8{font-size:8px}.h-fz-12{font-size:12px}.h-fz-16{font-size:16px}.h-fz-20{font-size:20px}.h-fz-24{font-size:24px}.h-fz-28{font-size:28px}.h-fz-32{font-size:32px}.h-fz-36{font-size:36px}.h-fz-40{font-size:40px}.h-fz-44{font-size:44px}.h-fz-48{font-size:48px}.h-fz-52{font-size:52px}.h-fz-56{font-size:56px}.h-fz-60{font-size:60px}.h-fz-64{font-size:64px}

.h-fz-common {
    font-size: 14px;
}

// Transformation
.h-text-lowercase {
    text-transform: lowercase;
}

.h-text-uppercase {
    text-transform: uppercase;
}

.h-text-capitalize {
    text-transform: capitalize;
}

// decorate
.h-text-underline,
.h-text-hover-underline.-activated,
.h-text-hover-underline:hover {
    text-decoration: underline;
}

.h-text-line-through {
    text-decoration: line-through;
}

// font style
.h-fs-inherit {
    font-style: inherit;
}

.h-fs-italic {
    font-style: italic;
}

.h-fs-normal {
    font-style: normal;
}

// align

.h-align-left,
.h-text-left {
    text-align: left;
}

.h-align-center,
.h-text-center {
    text-align: center;
}

.h-align-right,
.h-text-right {
    text-align: right;
}

@media only screen and (max-width: 640px) {
    .h-align-sm-left,
    .h-text-sm-left {
        text-align: left;
    }

    .h-align-sm-center,
    .h-text-sm-center {
        text-align: center;
    }

    .h-align-sm-right,
    .h-text-sm-right {
        text-align: right;
    }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
    .h-align-md-left,
    .h-text-md-left {
        text-align: left;
    }

    .h-align-md-center,
    .h-text-md-center {
        text-align: center;
    }

    .h-align-md-right,
    .h-text-md-right {
        text-align: right;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .h-align-lg-left,
    .h-text-lg-left {
        text-align: left;
    }

    .h-align-lg-center,
    .h-text-lg-center {
        text-align: center;
    }

    .h-align-lg-right,
    .h-text-lg-right {
        text-align: right;
    }
}

@media only screen and (min-width: 1441px) {
    .h-align-xl-left,
    .h-text-xl-left {
        text-align: left;
    }

    .h-align-xl-center,
    .h-text-xl-center {
        text-align: center;
    }

    .h-align-xl-right,
    .h-text-xl-right {
        text-align: right;
    }
}

.h-prevent-line-break {
    white-space: nowrap;
}

.h-text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.h-overflow-break-line {
    word-break: break-all;
}

.h-overflow-break-word {
    word-break: break-word;
}

// Font Weight
@each $fw, $value in (inherit: inherit, light: 300, regular: 400, medium: 500, semibold: 600, bold: 700) {
    .h-fw-#{$fw} {
        font-weight: $value;
    }

    .h-fw-#{$fw}-i {
        font-weight: $value !important;
    }
}
