/*------------------------------------
  CUSTOM OVERLAY
//------------------------------------*/

.sv-custom-overlay-panel {
    margin-top: 0;
}

.sv-custom-overlay-panel-wrapper {
    position: relative;
    z-index: 10;
}

.sv-custom-overlay-panel {
    position: absolute;
    width: 200px;
    z-index: 10;
    background: #fff;
    border: 1px solid $carbon--gray-20;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

    &.-large-size {
        width: 250px;
    }

    &.-top-left {
        bottom: 100%;
        right: 0;
    }

    &.-top-right {
        bottom: 100%;
        left: 0;
    }

    &.-bottom-left {
        top: 100%;
        right: 0;
    }

    &.-bottom-right {
        top: 100%;
        left: 0;
    }
}

/*------------------------------------
  OVERLAY MENU
//------------------------------------*/

.sv-overlay-menu.ui-overlaypanel {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    border: 0;
    margin-top: 4px;

    &:before, &:after {
        display: none;
    }

    .ui-overlaypanel-content {
        padding: 0;
    }
}

/**
 * Style 1
 */

.sv-overlay-menu.style-1 {
    .sv-overlay-menu__item {
        padding: 8px 28px;
        cursor: pointer;

        &:hover {
            background: $carbon--gray-20;
        }
    }
}

/**
 * Style 2
 */

.sv-overlay-menu.style-2 {
    width: 220px;
    background: $carbon--gray-10;

    .sv-overlay-menu__title {
        color: $carbon--gray-50;
        padding: 8px;
        display: flex;
        align-items: center;
    }

    .sv-overlay-menu__clear-btn {
        padding: 2px 24px 2px 8px;
        margin-right: 8px;
        display: block;
        font-size: 12px;
        line-height: 20px;
        border-radius: 40px;
        background: $sv-color--primary;
        color: #fff;
        position: relative;
        cursor: pointer;
    }

    .sv-overlay-menu__clear-icon {
        position: absolute;
        font-size: 14px;
        z-index: 1;
        height: 100%;
        width: 20px;
        top: 0;
        right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sv-overlay-menu__item-wrapper {
        padding: 0 16px 10px 16px;

        &:hover {
            padding-bottom: 11px;
            margin-bottom: -1px;
            background: $carbon--hover-ui;
            color: $carbon--disabled-3;
        }
    }

    .sv-overlay-menu__item {
        border-top: 1px solid $carbon--cool-gray-20;
        padding-top: 9px;
        color: $carbon--gray-70;
    }

    .sv-overlay-menu__item-wrapper.-activated {
        background: $carbon--gray-20;
        color: $carbon--gray-50;

        &:hover {
            background: $carbon--hover-selected-ui;
        }
    }
}
