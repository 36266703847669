$sv-bsv-height: 32px;

.button {
    &:disabled {
        cursor: not-allowed;
    }
}

.sv-group-btn {
    .sv-btn {
        margin: 2.5px;
    }
}

.sv-btn {
    padding: 0 12px;
    cursor: pointer;
    border: none;
    height: $sv-bsv-height;
    line-height: $sv-bsv-height;
    font-size: 14px;
    display: inline-flex;

    &.-block-el, &.h-block-el {
        display: flex;
    }

    &__icon {
        font-size: 18px;
    }

    &__label + &__icon {
        margin-left: 8px;
    }
}

.sv-btn.-icon-only {
    padding: 0;
    min-width: $sv-bsv-height;
    justify-content: center;
}

.sv-btn:not(.-no-focus-effect) {
    outline: 1px solid transparent;
    outline-offset: -3px;

    &:focus {
        outline-color: #fff;
    }

    &.--focus-style-1 {
        border: 2px solid transparent;
        line-height: $sv-bsv-height - 4px;
        padding: 0 10px;
    }

    &.--focus-style-1:focus {
        border-color: $sv-color--primary;
    }

    &.-icon-only.--focus-style-1 {
        padding: 0;
    }
}

