/*------------------------------------
  TOPBAR
//------------------------------------*/

.sv-topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $sv-color--primary;
    z-index: 999;
}

.sv-topbar__content-container {
    display: flex;
}

/**
 * Button
 */

.sv-topbar-btn {
    font-size: 20px;
    color: #fff;
    height: $sv-topbar-height;
    width: $sv-topbar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(.-highlight) {
        @include easing-animation(background-color);

        &:hover, &.-activated {
            background-color: $sv-color--primary-alt;
        }
    }

    &.-highlight {
        background-color: $sv-color--secondary;
        color: $sv-color--primary;
    }
}

/**
 * Left content
 */

.sv-topbar__left-content {
    display: flex;
    align-items: center;
    height: $sv-topbar-height;
}

// logo
.sv-topbar-logo-wrapper {
    display: block;
    margin: 0 8px;
}

.sv-topbar-logo {
    display: block;
    width: 32px;
    height: 32px;
}

// Info
.sv-topbar-info {
    font-size: 16px;
    color: #fff;
}

/**
 * Right content
 */

.sv-topbar__right-content {
    flex: 5 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// Sub system
.sv-topbar-subsystems-overlay {
    display: flex;
    flex-wrap: wrap;
    width: 320px;
}

.sv-topbar-subsystems-item {
    cursor: pointer;
    width: 50%;
    text-align: center;
    padding: 15px;
    @include easing-animation(background-color);
    border-bottom: 1px solid $carbon--gray-20;

    &:nth-last-child(-n+2) {
        border-bottom: 0;
    }

    &:nth-child(2n+1) {
        border-right: 1px solid $carbon--gray-20;
    }

    &__icon {
        color: $sv-color--primary;
        font-size: 48px;
        line-height: 1;
        margin-bottom: 12px;
    }

    &__subtitle, &__title {
        color: $carbon--gray-80;
    }

    &__title {
        font-weight: 700;
    }

    &:hover {
        background-color: $carbon--hover-ui;
    }
}

// User setting
.sv-topbar-user-setting-overlay {
    width: 260px;
    color: inherit;
}

.sv-topbar-user-info-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
}

.sv-topbar-user-info {
    position: relative;
    padding-left: 74px;
    padding-right: 16px;

    &__avatar {
        border-radius: 100%;
        width: 44px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto 0;
    }

    &__name {
        margin-bottom: 4px;
        color: $carbon--gray-80;
    }

    &__role {
        color: $carbon--gray-60;
    }
}

.sv-topbar-user-setting-action-item {
    display: block;
    border-top: 1px solid $carbon--gray-20;
    padding: 14px 16px 14px 55px;
    line-height: 20px;
    color: inherit;
    background-color: $carbon--gray-10;
    position: relative;
    @include easing-animation(background-color);

    &:hover {
        background-color: $carbon--hover-ui;
    }

    &__icon {
        color: $sv-color--primary;
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 100%;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-weight: bold;
    }
}

/*------------------------------------
  RiGHT SIDEBAR
//------------------------------------*/

.sv-right-sidebar-area {
    position: fixed;
    right: 0;
    height: calc(100vh - #{$sv-topbar-height});
    top: $sv-topbar-height;
    max-width: 320px;
    width: 100%;
    background-color: #fff;
    box-shadow: -2px 1px 4px 0 $carbon--gray-20;
    z-index: 99;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include easing-animation-fast(right);

    &.-show {
        right: 0;
    }

    &.-hide {
        right: -340px;
    }
}

.sv-right-sidebar-tabs-container {
    display: flex;
}

.sv-right-sidebar-tab-item {
    flex: 1 1;
    border-bottom: 2px solid $carbon--gray-20;
    cursor: pointer;
    line-height: 20px;
    padding: 10px 16px;
    @include easing-animation-fast();

    &:hover {
        color: $sv-color--text-1;
        border-color: $carbon--gray-30;
    }

    &.-activated {
        font-weight: 700;
        color: $sv-color--text-1;
        border-color: $sv-color--primary;
    }

    & + & {
        margin-left: 2px;
    }
}

.sv-right-sidebar-panel-container {
    overflow-y: auto;
}



