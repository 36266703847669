// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    // Firefox
    &::-moz-placeholder {
        color:   $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: $color;
    }
    // Safari and Chrome
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing:    $boxmodel;
    box-sizing:         $boxmodel;
}

// Transition
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition:      $transition;
    transition:         $transition;
}

// transform
@mixin transform($type) {
    -webkit-transform: $type;
    -moz-transform: $type;
    -ms-transform: $type;
    -o-transform: $type;
    transform: $type;
}

// easing-animation
@mixin easing-animation-flash($type: all, $effect: $sv-transition-effect-common) {
    @include transition($type $sv-transition-flash-time $effect);
}

@mixin easing-animation-fast($type: all, $effect: $sv-transition-effect-common) {
    @include transition($type $sv-transition-short-time $effect);
}

@mixin easing-animation($type: all, $effect: $sv-transition-effect-common) {
    @include transition($type $sv-transition-time $effect);
}

@mixin easing-animation-long($type: all, $effect: $sv-transition-effect-common) {
    @include transition($type $sv-transition-long-time $effect);
}

// flex
@mixin flexbox {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex-direction($value: row) {
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}
// Shorter version:
@mixin flex-dir($args...) { @include flex-direction($args...); }

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

@mixin order($int: 0) {
    -webkit-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-grow($int: 0) {
    -webkit-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {

    // Set a variable to be used by box-flex properties

    // Box-Flex only supports a flex-grow value so let's grab the
    // first item in the list and just return that.
    @if type-of($fg) == 'list' {
    }

    -webkit-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin flex-just($args...) { @include justify-content($args...)};

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -ms-flex-align: end;
    } @else {
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}
