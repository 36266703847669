.sv-form-action-row {
    display: flex;
}

.sv-form-action-row__left-side,
.sv-form-action-row__right-side {
    margin-top: 24px;
    height: 40px;
    display: flex;
    align-items: center;
}

.sv-form-action-row__main-content {
    flex: 1;
}


