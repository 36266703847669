$sv-toast--warn-bgc: #fff8e2;

/*------------------------------------
  GENERAL
//------------------------------------*/

.sv-toast {
    line-height: 18px;
    min-width: 288px;
    width: auto;
    margin-bottom: 16px;

    /**
     * Icon
     */

    .pi {
        font-family: "carbon" !important;
    }

    .pi-times:before {
        content: "\e046";
    }

    .pi-exclamation-triangle:before {
        content: "\e2cb";
    }

    .pi-check:before {
        content: "\e029";
    }

    /**
     * Other
     */

    .ui-toast-summary {
        color: $sv-color--text-1;
        font-weight: 500;
        padding-bottom: 3px;
    }

    .ui-toast-detail {
        white-space: pre-line;
        color: $sv-color--text-common;
    }

    .ui-toast-message.ui-toast-message.ui-toast-message {
        margin-bottom: 16px;
        border-left: 3px solid transparent;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    }

    .ui-toast-message-content {
        padding: 0 64px 0 52px;
    }

    .ui-toast-icon {
        left: 16px;
        top: 14px;
        font-size: 20px;
    }

    .ui-toast-message-text-content {
        padding: 16px 0;
        margin: 0;
    }

    .ui-toast-close-icon.ui-toast-close-icon.ui-toast-close-icon.ui-toast-close-icon {
        color: $sv-color--text-1;
    }

    .ui-toast-close-icon {
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/**
 * Inline style
 */

.sv-toast.-style-inline {
    line-height: 24px;

    .ui-toast-message-text-content {
        padding: 12px 0;
        display: flex;
        flex-wrap: wrap;
    }

    .ui-toast-summary {
        margin-right: 4px;
        padding-bottom: 0;
    }
}

/*------------------------------------
  INFO
//------------------------------------*/

.sv-toast .ui-toast-message-info.ui-toast-message-info.ui-toast-message-info {
    border-color: $carbon--blue-50;
    background: $carbon--blue-10;
    width: 400px;

    .ui-toast-message-content {
        padding-left: 16px;
    }

    .ui-toast-icon {
        display: none;
    }
}


/*------------------------------------
  SUCCESS
//------------------------------------*/

.sv-toast .ui-toast-message-success.ui-toast-message-success.ui-toast-message-success {
    border-color: $carbon--green-50;
    background: $carbon--green-10;

    .ui-toast-icon {
        color: $carbon--green-50;
    }
}

/*------------------------------------
  WARNING
//------------------------------------*/

.sv-toast .ui-toast-message-warn.ui-toast-message-warn.ui-toast-message-warn {
    border-color: $carbon--yellow-30;
    max-width: 800px;
    background: $sv-toast--warn-bgc;

    .ui-toast-icon {
        color: $carbon--yellow-30;
    }
}

/*------------------------------------
  ERROR
//------------------------------------*/

.sv-toast .ui-toast-message-error.ui-toast-message-error.ui-toast-message-error {
    border-color: $carbon--red-60;
    width: 400px;
    background: $carbon--red-10;

    .ui-toast-icon {
        color: $carbon--red-60;

        &:before {
            content: "\e0a6";
        }
    }
}


