// hidden
.h-hidden, [hidden] {
    display: none !important;
}

.h-visible {
    visibility: visible !important;
}

.h-invisible {
    visibility: hidden !important;
}

@media only screen and (min-width: 1025px) {
    .h-hidden-on-desktop {
        display: none;
    }

    .h-hidden-on-desktop-i {
        display: none !important;
    }
}

@media only screen and (max-width: 1024px) {
    .h-hidden-on-small-device {
        display: none;
    }

    .h-hidden-on-small-device-i {
        display: none !important;
    }
}

// display
.h-block-el {
    display: block;
}

.h-inline-block-el {
    display: inline-block;
}

.h-flex-el {
    @include flexbox();
}

.h-inline-flex-el {
    @include inline-flex();
}

.h-flex-el, .h-inline-flex-el {
    &.-h-align-content-top {
        @include align-items(flex-start);
    }

    &.-h-align-content-middle {
        @include align-items(center);
    }

    &.-h-align-content-bottom {
        @include align-items(flex-end);
    }

    &.-h-align-content-center {
        @include justify-content(center);
    }

    &.-h-align-content-left {
        @include justify-content(flex-start);
    }

    &.-h-align-content-right {
        @include justify-content(flex-end);
    }

    &.-h-space-between {
        @include justify-content(space-between);
    }

    &.-h-wrap-content {
        @include flex-wrap(wrap);
    }

    &.-h-x-direction {
        @include flex-direction(row);
    }

    &.-h-x-reverse-direction {
        @include flex-direction(row-reverse);
    }

    &.-h-y-direction {
        @include flex-direction(column);
    }

    &.-h-y-reverse-direction {
        @include flex-direction(column-reverse);
    }
}

.h-flex-wrap {
    @include flex-wrap(wrap);
}

.h-flex-ai--fs {
    @include align-items(flex-start);
}

.h-flex-ai--c {
    @include align-items(center);
}

.h-flex-ai--bl {
    @include align-items(baseline);
}

.h-flex-ai--fe {
    @include align-items(flex-end);
}

.h-flex-jc--fs {
    @include justify-content(flex-start);
}

.h-flex-jc--c {
    @include justify-content(center);
}

.h-flex-jc--fe {
    @include justify-content(flex-end);
}

.h-flex-jc--sb {
    @include justify-content(space-between);
}

.h-flex-fd--r {
    @include flex-direction(row);
}

.h-flex-fd--rr {
    @include flex-direction(row-reverse);
}

.h-flex-fd--c {
    @include flex-direction(column)
}

.h-flex-fd--cr {
    @include flex-direction(column-reverse)
}

.h-block-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Cursor
.h-cursor-pointer {
    cursor: pointer;
}

.h-cursor-not-allowed {
    cursor: not-allowed;
}

// overflow
.h-overflow-hidden {
    overflow: hidden;
}

.h-overflow-auto {
    overflow: auto;
}

.h-overflow-x-auto {
    overflow-x: auto;
}

.h-overflow-y-auto {
    overflow-y: auto;
}

.h-overflow-x-hidden {
    overflow-x: hidden;
}

.h-overflow-y-hidden {
    overflow-y: hidden;
}

// vertical align

.h-vertical-align-top, th.h-align-top, td.h-align-top {
    vertical-align: top;
}

.h-vertical-align-middle, th.h-align-middle, td.h-align-middle {
    vertical-align: middle;
}

.h-vertical-align-bottom, th.h-align-bottom, td.h-align-bottom {
    vertical-align: bottom;
}

// separate

.h-x-line-top--size-1 {
    border-top: 1px solid $carbon--gray-20;
}

.h-x-line-bottom--size-1 {
    border-bottom: 1px solid $carbon--gray-20;
}

.h-y-line-left--size-1 {
    border-left: 1px solid $carbon--gray-20;
}

.h-y-line-right--size-1 {
    border-right: 1px solid $carbon--gray-20;
}
