.sv-process-container {
    display: flex;
}

.sv-process {
    flex: 1 1;
}

.sv-process__decoration {
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:after {
        content: '';
        display: block;
        flex: 1;
        height: 1px;
        background-color: $carbon--gray-20;
    }
}

.sv-process:last-of-type .sv-process__decoration:after {
    display: none;
}


// unpass
.sv-process.-unpass {
    .sv-process__decoration {
        color: $carbon--gray-30;
    }
}

// current
.sv-process.-current {
    .sv-process__decoration {
        color: $sv-color--secondary;
    }

    .sv-process__label {
        color: $sv-color--text-1;
        font-weight: 500;
    }
}

// pass
.sv-process.-pass {
    .sv-process__decoration {
        color: $carbon--green-60;
    }

    .sv-process__label {
        color: $sv-color--text-2;
    }
}
