/*------------------------------------
  GENERAL
//------------------------------------*/

.sv-data-view-area {
    display: flex;

    &, &__left-block, &__main-block {
        height: 100%;
    }
}

/*------------------------------------
  LEFT BLOCK
//------------------------------------*/

.sv-data-view-area__left-block {
    flex: 1 1;
    overflow: hidden;
    min-width: 320px;
    padding-right: 24px;
}

.sv-data-tree-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sv-data-tree-content-block {
    position: relative;
    overflow: hidden;
    flex: 1 1;

    .sv-tree {
        overflow: auto;
        display: block;
        height: 100%;
    }
}

.sv-data-tree-tool-toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
}

/*------------------------------------
  MAIN BLOCK
//------------------------------------*/

.sv-data-view-area__main-block {
    display: flex;
    flex-direction: column;
    flex: 5 1;
    overflow: hidden;

    .sv-table-wrapper {
        flex: 1 1;
    }
}

.sv-data-view-main-action-area,
.sv-data-view-record-action-area {
    display: flex;

    &__left-block, &__right-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__left-block {
        .sv-btn {
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    &__right-block {
        justify-content: flex-end;

        .sv-btn {
            margin-left: 8px;
            margin-bottom: 8px;
        }
    }
}

.sv-data-view-record-action-area__left-block {
    align-items: center;
}

/**
 * Main action
 */

.sv-data-view-main-action-area {
    padding-bottom: 8px;
}

/**
 * Record action
 */

.sv-data-view-record-action-area {
    background: $sv-color--primary;
    color: #fff;
    padding-top: 8px;
}

.sv-data-view-record-action-btn {
    color: #fff;
    font-size: 20px;
    background: transparent;
}

/**
 * Table
 */

.sv-data-view-area .sv-table-wrapper {
    overflow: hidden;
    flex: 1 1;
}

.sv-table {
    thead tr {
        background-color: $carbon--gray-20;
    }

    td {
        color: $sv-color--text-common;
    }
}

.sv-table.sv-table.sv-table.sv-table {
    tbody > tr {
        background-color: #fff;

        &:hover {
            background: $carbon--hover-ui;
        }
    }

    th, td {
        border: 0;
        padding-left: 8px;
        padding-right: 8px;
        line-height: 20px;
    }

    th {
        background-color: inherit;
        padding-top: 14px;
        padding-bottom: 14px;
        color: $sv-color--text-1;

        .ui-sortable-column-icon {
            font-size: 12px;
        }
    }

    th.ui-sortable-column {
        &:hover {
            background-color: $carbon--hover-selected-ui;
        }

        &:focus {
            color: $sv-color--text-1;
            outline: 2px solid $sv-color--primary;
            outline-offset: -2px;
        }

        &.ui-state-highlight {
            color: $sv-color--text-1;
        }

        &.ui-state-highlight .ui-sortable-column-icon {
            color: inherit;
        }
    }

    td {
        background: inherit;
        padding-top: 6px;
        padding-bottom: 6px;
        border-top: 1px solid $carbon--gray-20;
    }
}

// fixed header when scroll
.sv-table {
    &, .ui-table, .ui-table-scrollable-view {
        height: 100%;
    }

    .ui-table {
        display: flex;
        flex-direction: column;
    }

    .ui-table-scrollable-wrapper {
        flex: 1 1;
        overflow: hidden;
    }

    .ui-table-scrollable-view {
        display: flex;
        flex-direction: column;
    }

    .ui-table-scrollable-body {
        flex: 1 10;
    }
}

/**
 * Table row icon
 */

.sv-tr-icon-group {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;

    &.-align-left {
        justify-content: flex-start;
    }

    &.-align-center {
        justify-content: center;
    }

    &.-align-right {
        justify-content: flex-end;
    }
}

.sv-tr-icon {
    height: 20px;
    font-size: 16px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
}

/**
 * Table expanded row
 */

.sv-table.-expandable-row tr {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

tr.sv-expanded-tr-top-side.sv-expanded-tr-top-side {
    border: 2px solid $sv-color--primary;
    border-bottom: 0;
}

tr.sv-expanded-tr-bottom-side.sv-expanded-tr-bottom-side {
    border: 2px solid $sv-color--primary;
    border-top: 0;
}

.sv-table.sv-table.sv-table.sv-table tbody > tr.sv-expanded-tr-bottom-side:hover {
    background-color: #fff;
}

.sv-table.sv-table.sv-table.sv-table td.sv-expanded-tr-cell {
    padding: 0;
}

/**
 * Table with status style border left
 */

.sv-table.-status-style-border-left {
    tr {
        border-left-style: solid;
        border-left-width: 3px;
    }

    thead tr {
        border-left-color: $carbon--gray-20;
    }

    tbody > tr:not(.sv-tr-status) {
        border-left-color: #fff;

        &:hover {
            border-left-color: $carbon--hover-ui;
        }
    }

    tbody > tr.sv-expanded-tr-top-side,
    tbody > tr.sv-expanded-tr-bottom-side {
        border-left-width: 2px;

        &, &:hover {
            border-left-color: $sv-color--primary;
        }
    }
}

.sv-tr-status {
    td {
        color: $sv-color--text-1;
    }
}

tr.sv-tr-status-danger.sv-tr-status-danger {
    border-left-color: $carbon--red-60;
}

tr.sv-tr-status-warning.sv-tr-status-warning {
    border-left-color: $carbon--yellow-20;
}

tr.sv-tr-status-success.sv-tr-status-success {
    border-left-color: $carbon--green-60;
}
