@charset "UTF-8";

@font-face {
  font-family: "usmart-pic";
  src:url("fonts/usmart-pic.eot");
  src:url("fonts/usmart-pic.eot?#iefix") format("embedded-opentype"),
    url("fonts/usmart-pic.woff") format("woff"),
    url("fonts/usmart-pic.ttf") format("truetype"),
    url("fonts/usmart-pic.svg#usmart-pic") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-usp] {
  font-family: "usmart-pic" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-usp]:before {
  content: attr(data-usp);
}

[class^="usp-"],
[class*=" usp-"] {
  font-family: "usmart-pic" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.usp-academy-01:before {
  content: "\61";
}
.usp-academy-02:before {
  content: "\62";
}
.usp-adding:before {
  content: "\63";
}
.usp-air-conditioner:before {
  content: "\64";
}
.usp-airplane:before {
  content: "\65";
}
.usp-alert-01:before {
  content: "\66";
}
.usp-alert-02:before {
  content: "\67";
}
.usp-all-weather:before {
  content: "\68";
}
.usp-americas:before {
  content: "\69";
}
.usp-analytics-01:before {
  content: "\6a";
}
.usp-analytics-02:before {
  content: "\6b";
}
.usp-analytics-03:before {
  content: "\6c";
}
.usp-analytics-04:before {
  content: "\6d";
}
.usp-analytics-05:before {
  content: "\6e";
}
.usp-analytics-06:before {
  content: "\6f";
}
.usp-analytics-07:before {
  content: "\70";
}
.usp-api:before {
  content: "\71";
}
.usp-assistant:before {
  content: "\72";
}
.usp-australia:before {
  content: "\73";
}
.usp-automate-modular-management:before {
  content: "\74";
}
.usp-backup-01:before {
  content: "\75";
}
.usp-backup-02:before {
  content: "\76";
}
.usp-backup-03:before {
  content: "\77";
}
.usp-bee:before {
  content: "\78";
}
.usp-bike:before {
  content: "\79";
}
.usp-brain:before {
  content: "\7a";
}
.usp-brand-assets:before {
  content: "\41";
}
.usp-brand-guidelines:before {
  content: "\42";
}
.usp-brand-tutorials:before {
  content: "\43";
}
.usp-build:before {
  content: "\44";
}
.usp-building-01:before {
  content: "\45";
}
.usp-building-02:before {
  content: "\46";
}
.usp-building-02-1:before {
  content: "\47";
}
.usp-building-03:before {
  content: "\48";
}
.usp-building-03-1:before {
  content: "\49";
}
.usp-bulldozer:before {
  content: "\4a";
}
.usp-bus:before {
  content: "\4b";
}
.usp-calendar-01:before {
  content: "\4c";
}
.usp-calendar-02:before {
  content: "\4d";
}
.usp-calendar-03:before {
  content: "\4e";
}
.usp-camera:before {
  content: "\4f";
}
.usp-car:before {
  content: "\50";
}
.usp-card:before {
  content: "\51";
}
.usp-cart:before {
  content: "\52";
}
.usp-chip-01:before {
  content: "\53";
}
.usp-chip-02:before {
  content: "\54";
}
.usp-clip:before {
  content: "\55";
}
.usp-cloud-download:before {
  content: "\56";
}
.usp-cloud-foundry:before {
  content: "\57";
}
.usp-cloud-upload:before {
  content: "\58";
}
.usp-cloudy:before {
  content: "\59";
}
.usp-computing-01:before {
  content: "\5a";
}
.usp-computing-02:before {
  content: "\30";
}
.usp-cylinder:before {
  content: "\31";
}
.usp-delete:before {
  content: "\32";
}
.usp-delete-circle:before {
  content: "\33";
}
.usp-devops:before {
  content: "\34";
}
.usp-digital:before {
  content: "\35";
}
.usp-dna:before {
  content: "\36";
}
.usp-docker:before {
  content: "\37";
}
.usp-document-01:before {
  content: "\38";
}
.usp-document-02:before {
  content: "\39";
}
.usp-document-image:before {
  content: "\21";
}
.usp-download:before {
  content: "\22";
}
.usp-electronic-charger:before {
  content: "\23";
}
.usp-elevator:before {
  content: "\24";
}
.usp-engine:before {
  content: "\25";
}
.usp-europe-africa:before {
  content: "\26";
}
.usp-expand:before {
  content: "\27";
}
.usp-eye:before {
  content: "\28";
}
.usp-facial-recognition:before {
  content: "\29";
}
.usp-factory:before {
  content: "\2a";
}
.usp-factory-car:before {
  content: "\2b";
}
.usp-farm-car:before {
  content: "\2c";
}
.usp-fill-16:before {
  content: "\2d";
}
.usp-fill-16-1:before {
  content: "\2e";
}
.usp-fill-19:before {
  content: "\2f";
}
.usp-fill-19-1:before {
  content: "\3a";
}
.usp-finance-chat:before {
  content: "\3b";
}
.usp-finance-graph:before {
  content: "\3c";
}
.usp-finance-person:before {
  content: "\3d";
}
.usp-fingerprint:before {
  content: "\3e";
}
.usp-foggy:before {
  content: "\3f";
}
.usp-free-trial:before {
  content: "\40";
}
.usp-game:before {
  content: "\5b";
}
.usp-gameboy:before {
  content: "\5d";
}
.usp-gbs:before {
  content: "\5e";
}
.usp-germs-01:before {
  content: "\5f";
}
.usp-germs-02:before {
  content: "\60";
}
.usp-germs-03:before {
  content: "\7b";
}
.usp-global-finance:before {
  content: "\7c";
}
.usp-globe-01:before {
  content: "\7d";
}
.usp-globe-02:before {
  content: "\7e";
}
.usp-government:before {
  content: "\5c";
}
.usp-group:before {
  content: "\e000";
}
.usp-gts:before {
  content: "\e001";
}
.usp-hail-01:before {
  content: "\e002";
}
.usp-hail-02:before {
  content: "\e003";
}
.usp-handle:before {
  content: "\e004";
}
.usp-headphones:before {
  content: "\e005";
}
.usp-headquarters:before {
  content: "\e006";
}
.usp-heart:before {
  content: "\e007";
}
.usp-heart-1:before {
  content: "\e008";
}
.usp-heart-recycle:before {
  content: "\e009";
}
.usp-home-01:before {
  content: "\e00a";
}
.usp-home-02:before {
  content: "\e00b";
}
.usp-home-03:before {
  content: "\e00c";
}
.usp-home-wifi:before {
  content: "\e00d";
}
.usp-hr-01:before {
  content: "\e00e";
}
.usp-hr-02:before {
  content: "\e00f";
}
.usp-hr-03:before {
  content: "\e010";
}
.usp-hr-04:before {
  content: "\e011";
}
.usp-hurricane-01:before {
  content: "\e012";
}
.usp-hurricane-02:before {
  content: "\e013";
}
.usp-hurricane-03:before {
  content: "\e014";
}
.usp-ibm-cloud-builder-professionalservices:before {
  content: "\e015";
}
.usp-ibm-cloud-data-encryption-services:before {
  content: "\e016";
}
.usp-ibm-cloud-ecosystem:before {
  content: "\e017";
}
.usp-ibm-cloud-for-sap:before {
  content: "\e018";
}
.usp-ibm-cloud-infrastructure-for-analytics:before {
  content: "\e019";
}
.usp-ibm-cloud-managed-services:before {
  content: "\e01a";
}
.usp-ibm-cloud-managed-services-for-oracle-app:before {
  content: "\e01b";
}
.usp-ibm-cms-pricing:before {
  content: "\e01c";
}
.usp-ibm-demand-programs:before {
  content: "\e01d";
}
.usp-ibm-design-language:before {
  content: "\e01e";
}
.usp-ibm-development-test-environ-services:before {
  content: "\e01f";
}
.usp-ibm-pure-application:before {
  content: "\e020";
}
.usp-idea:before {
  content: "\e021";
}
.usp-imac:before {
  content: "\e022";
}
.usp-infrastructure-as-service:before {
  content: "\e023";
}
.usp-integration-01:before {
  content: "\e024";
}
.usp-integration-02:before {
  content: "\e025";
}
.usp-integration-03:before {
  content: "\e026";
}
.usp-integration-04:before {
  content: "\e027";
}
.usp-integration-05:before {
  content: "\e028";
}
.usp-it-service:before {
  content: "\e029";
}
.usp-java:before {
  content: "\e02a";
}
.usp-javascript:before {
  content: "\e02b";
}
.usp-knight:before {
  content: "\e02c";
}
.usp-lightning:before {
  content: "\e02d";
}
.usp-list-01:before {
  content: "\e02e";
}
.usp-list-02:before {
  content: "\e02f";
}
.usp-location:before {
  content: "\e030";
}
.usp-lock:before {
  content: "\e031";
}
.usp-lung:before {
  content: "\e032";
}
.usp-magnifying-glass:before {
  content: "\e033";
}
.usp-marketplace:before {
  content: "\e034";
}
.usp-mas:before {
  content: "\e035";
}
.usp-medicine-bowl:before {
  content: "\e036";
}
.usp-microscope:before {
  content: "\e037";
}
.usp-mist-01:before {
  content: "\e038";
}
.usp-mist-02:before {
  content: "\e039";
}
.usp-mist-cloud:before {
  content: "\e03a";
}
.usp-mobile:before {
  content: "\e03b";
}
.usp-mobile-add:before {
  content: "\e03c";
}
.usp-mobile-chat:before {
  content: "\e03d";
}
.usp-mobile-devices:before {
  content: "\e03e";
}
.usp-mqa:before {
  content: "\e03f";
}
.usp-nature:before {
  content: "\e040";
}
.usp-networking-01:before {
  content: "\e041";
}
.usp-networking-02:before {
  content: "\e042";
}
.usp-networking-03:before {
  content: "\e043";
}
.usp-night:before {
  content: "\e044";
}
.usp-oil:before {
  content: "\e045";
}
.usp-partly-cloudy:before {
  content: "\e046";
}
.usp-partly-foggy:before {
  content: "\e047";
}
.usp-partners-of-the-ibm-cloud-marketplace:before {
  content: "\e048";
}
.usp-pattern:before {
  content: "\e049";
}
.usp-performance:before {
  content: "\e04a";
}
.usp-phone:before {
  content: "\e04b";
}
.usp-pills:before {
  content: "\e04c";
}
.usp-plug:before {
  content: "\e04d";
}
.usp-podcast:before {
  content: "\e04e";
}
.usp-police:before {
  content: "\e04f";
}
.usp-printer:before {
  content: "\e050";
}
.usp-puzzle:before {
  content: "\e051";
}
.usp-question:before {
  content: "\e052";
}
.usp-rain-01:before {
  content: "\e053";
}
.usp-rain-02:before {
  content: "\e054";
}
.usp-rain-cloud:before {
  content: "\e055";
}
.usp-rank:before {
  content: "\e056";
}
.usp-refresh-01:before {
  content: "\e057";
}
.usp-refresh-02:before {
  content: "\e058";
}
.usp-relationship:before {
  content: "\e059";
}
.usp-reload:before {
  content: "\e05a";
}
.usp-reload-1:before {
  content: "\e05b";
}
.usp-robot-01:before {
  content: "\e05c";
}
.usp-robot-02:before {
  content: "\e05d";
}
.usp-rocket:before {
  content: "\e05e";
}
.usp-rook:before {
  content: "\e05f";
}
.usp-saas-enablement:before {
  content: "\e060";
}
.usp-satellite:before {
  content: "\e061";
}
.usp-scale:before {
  content: "\e062";
}
.usp-seeding:before {
  content: "\e063";
}
.usp-semiconductor:before {
  content: "\e064";
}
.usp-servers-01:before {
  content: "\e065";
}
.usp-servers-02:before {
  content: "\e066";
}
.usp-servers-03:before {
  content: "\e067";
}
.usp-setting-01:before {
  content: "\e068";
}
.usp-setting-02:before {
  content: "\e069";
}
.usp-setting-03:before {
  content: "\e06a";
}
.usp-shoe:before {
  content: "\e06b";
}
.usp-shop:before {
  content: "\e06c";
}
.usp-shopping-card:before {
  content: "\e06d";
}
.usp-shower:before {
  content: "\e06e";
}
.usp-shrink:before {
  content: "\e06f";
}
.usp-single-sign-on:before {
  content: "\e070";
}
.usp-snow:before {
  content: "\e071";
}
.usp-softlayer-enablement:before {
  content: "\e072";
}
.usp-solve:before {
  content: "\e073";
}
.usp-solve02:before {
  content: "\e074";
}
.usp-speed:before {
  content: "\e075";
}
.usp-steering-wheel:before {
  content: "\e076";
}
.usp-storage-01:before {
  content: "\e077";
}
.usp-storage-02:before {
  content: "\e078";
}
.usp-storage-03:before {
  content: "\e079";
}
.usp-strategy-01:before {
  content: "\e07a";
}
.usp-strategy-02:before {
  content: "\e07b";
}
.usp-student:before {
  content: "\e07c";
}
.usp-subway:before {
  content: "\e07d";
}
.usp-sunny:before {
  content: "\e07e";
}
.usp-sunrise:before {
  content: "\e07f";
}
.usp-sunset:before {
  content: "\e080";
}
.usp-swipe-screen-hand:before {
  content: "\e081";
}
.usp-tag:before {
  content: "\e082";
}
.usp-tags:before {
  content: "\e083";
}
.usp-tap:before {
  content: "\e084";
}
.usp-target-01:before {
  content: "\e085";
}
.usp-target-02:before {
  content: "\e086";
}
.usp-television:before {
  content: "\e087";
}
.usp-temperature-01:before {
  content: "\e088";
}
.usp-temperature-02:before {
  content: "\e089";
}
.usp-temperature-03:before {
  content: "\e08a";
}
.usp-temperature-04:before {
  content: "\e08b";
}
.usp-time-01:before {
  content: "\e08c";
}
.usp-time-02:before {
  content: "\e08d";
}
.usp-tornado:before {
  content: "\e08e";
}
.usp-touch-screen:before {
  content: "\e08f";
}
.usp-touch-screen-hand:before {
  content: "\e090";
}
.usp-transform:before {
  content: "\e091";
}
.usp-trash:before {
  content: "\e092";
}
.usp-triangle-flask:before {
  content: "\e093";
}
.usp-truck:before {
  content: "\e094";
}
.usp-unlock:before {
  content: "\e095";
}
.usp-untitled:before {
  content: "\e096";
}
.usp-upload:before {
  content: "\e097";
}
.usp-user:before {
  content: "\e098";
}
.usp-user-persentation:before {
  content: "\e099";
}
.usp-video:before {
  content: "\e09a";
}
.usp-video-playing:before {
  content: "\e09b";
}
.usp-vmware-on-ibm-cloud:before {
  content: "\e09c";
}
.usp-vpn-01:before {
  content: "\e09d";
}
.usp-vpn-02:before {
  content: "\e09e";
}
.usp-washing-machine:before {
  content: "\e09f";
}
.usp-weather:before {
  content: "\e0a0";
}
.usp-weather-1:before {
  content: "\e0a1";
}
.usp-web-01:before {
  content: "\e0a2";
}
.usp-web-02:before {
  content: "\e0a3";
}
.usp-web-03:before {
  content: "\e0a4";
}
.usp-web-04:before {
  content: "\e0a5";
}
.usp-webcast-01:before {
  content: "\e0a6";
}
.usp-webcast-02:before {
  content: "\e0a7";
}
.usp-webcast-03:before {
  content: "\e0a8";
}
.usp-wheat:before {
  content: "\e0a9";
}
.usp-wifi:before {
  content: "\e0aa";
}
.usp-wifi-1:before {
  content: "\e0ab";
}
.usp-wifi-chat:before {
  content: "\e0ac";
}
.usp-wind:before {
  content: "\e0ad";
}
.usp-wind-cloud:before {
  content: "\e0ae";
}
.usp-wind-power:before {
  content: "\e0af";
}
.usp-wine-and-glass:before {
  content: "\e0b0";
}
.usp-wire-tower:before {
  content: "\e0b1";
}
.usp-wireless-game:before {
  content: "\e0b2";
}
