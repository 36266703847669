/*------------------------------------
  TAG
//------------------------------------*/

.sv-tag {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    height: 24px;
    border-radius: 15px;
    letter-spacing: 0.32px;

    &.-has-icon {
        padding-right: 0;
    }

    &.-focus-effect {
        &:focus {
            box-shadow: 0 0 0 2px $sv-color--primary inset;
        }
    }

    &__icon {
        display: flex;
        margin-left: 4px;
        margin-right: 2px;
        width: 20px;
        height: 20px;
        font-size: 16px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        &.-small {
            margin-right: 4px;
            width: 16px;
            height: 16px;
            font-size: 10px;
        }
    }
}

/*------------------------------------
  MULTI TAGS FIELD
//------------------------------------*/

.sv-multi-tags-field {
    resize: vertical;
    overflow-y: auto;
    border-bottom: 1px solid $carbon--gray-50;
    background-color: $carbon--gray-10;
    padding: 8px 4px 2px;

    .sv-tag {
        margin-bottom: 8px;
        margin-left: 4px;
        margin-right: 4px;
    }
}
