.sv-page-container {
    height: 100vh;
}

.sv-page-body-area {
    padding-top: $sv-topbar-height;
    height: 100%;
    display: flex;
}

.sv-page-body-area {
    @include easing-animation-fast(padding);
}

.sv-page-menu-area, .sv-topbar {
    @include easing-animation-fast();
}

.sv-page-container.-expansion-mode-inactive {
    .sv-topbar {
        top: 0;
    }
}

.sv-page-container.-expansion-mode-active {
    .sv-topbar {
        top: - $sv-topbar-height;
    }
}

@media (min-width: 1025px) {
    .sv-page-container.-menu-expanded-in-desktop {
        .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-inactive {
            display: none;
        }

        .sv-page-body-area {
            padding-left: $sv-left-menu-width;
        }

        .sv-page-menu-area {
            width: $sv-left-menu-width;
        }
    }

    .sv-page-container.-menu-collapsed-in-desktop {
        .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-active {
            display: none;
        }

        .sv-page-body-area {
            padding-left: 48px;
        }

        .sv-page-menu-area {
            width: 48px;
        }

        .sv-page-menu-area:not(:hover) {
            .sv-page-menu-item--level-0 > .sv-page-menu-item-link {
                display: none;
            }

            .sv-page-submenu--level-2 {
                display: none;
            }

            .ui-autocomplete-panel {
                display: none;
            }
        }
    }

    .sv-page-container.-menu-collapsed-in-desktop.-expansion-mode-inactive {
        .sv-page-menu-area:hover {
            width: $sv-left-menu-width;
        }
    }

    .sv-page-container.-expansion-mode-inactive {
        .sv-page-menu-area {
            left: 0;
        }
    }

    .sv-page-container.-expansion-mode-active {
        .sv-page-body-area {
            padding: 0;
        }

        .sv-page-menu-area {
            left: - $sv-left-menu-width;
        }
    }
}

@media (max-width: 1024px) {
    .sv-page-content-area {
        width: 100vw;
    }

    .sv-page-container.-menu-expanded-in-small-device {
        .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-inactive {
            display: none;
        }

        .sv-page-body-area {
            padding-left: $sv-left-menu-width;
        }

        .sv-page-menu-area {
            width: $sv-left-menu-width;
        }
    }

    .sv-page-container.-menu-collapsed-in-small-device {
        .sv-topbar-menu-collapse-toggle .sv-topbar-btn__icon.-state-active {
            display: none;
        }

        .sv-page-menu-area {
            left: - $sv-left-menu-width;
        }
    }

    .sv-page-container.-expansion-mode-inactive.-menu-expanded-in-small-device {
        .sv-page-menu-area {
            left: 0;
        }
    }

    .sv-page-container.-expansion-mode-active {
        .sv-page-body-area {
            padding: 0;
        }

        .sv-page-menu-area {
            left: - $sv-left-menu-width;
        }
    }
}

.sv-page-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #424242;
    opacity: 0.7;
    top: 0;
    left: 0;
    z-index: 990;
    display: none;
}
