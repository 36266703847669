/*------------------------------------
  ATTACHMENT
//------------------------------------*/

.sv-attachment {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    background-color: $carbon--gray-10;
}

.sv-attachment__checkbox {
    margin-right: 16px;
}

.sv-attachment__label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sv-attachment__icon {
    color: $sv-color--primary;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    @include easing-animation-flash(color);

    &:hover {
        color: $sv-color--primary-alt;
    }
}
