@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url(HelveticaNeue-Light.otf);
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 400;
    src: url(HelveticaNeue-LightItalic.otf);
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    src: url(HelveticaNeue.otf);
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    src: url(HelveticaNeue-Bold.otf);
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 700;
    src: url(HelveticaNeue-BoldItalic.otf);
}



