.sv-timeline-container {
    //background-color: #eeeeee;
}

.sv-timeline-period {
    padding-left: 50px;
    padding-bottom: 12px;
    font-weight: 500;
    color: $sv-color--primary;
}

.sv-timeline {
    padding-left: 96px;
    position: relative;
}

.sv-timeline__time {
    font-weight: 500;
    color: $sv-color--text-1;
    position: absolute;
    line-height: 16px;
    left: 0;
    top: 0;
}

.sv-timeline__decoration {
    position: absolute;
    top: 2px;
    left: 60px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    &:before {
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        border: 2px solid $sv-color--secondary;
        border-radius: 50%;
    }

    &:after {
        content: '';
        flex: 1;
        margin-top: 4px;
        margin-bottom: 4px;
        background-color: $carbon--gray-20;
        width: 1px;
    }
}

.sv-timeline__meta-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
}

.sv-timeline__meta-item {
    color: $sv-color--primary;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;

    &:not(:last-of-type) {
        &:after {
            content: '|';
            color: $carbon--gray-60;
            font-style: normal;
            margin-left: 8px;
            margin-right: 12px;
        }
    }
}

.sv-timeline__description {
    color: $sv-color--text-1;
    padding-bottom: 22px;
}
