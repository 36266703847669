// Carbon color

$carbon--black-100: #000000 !default;
$carbon--blue-10: #edf5ff !default;
$carbon--blue-20: #d0e2ff !default;
$carbon--blue-30: #a6c8ff !default;
$carbon--blue-40: #78a9ff !default;
$carbon--blue-50: #4589ff !default;
$carbon--blue-60: #0f62fe !default;
$carbon--blue-70: #0043ce !default;
$carbon--blue-80: #002d9c !default;
$carbon--blue-90: #001d6c !default;
$carbon--blue-100: #001141 !default;
$carbon--cool-gray-10: #f2f4f8 !default;
$carbon--cool-gray-20: #dde1e6 !default;
$carbon--cool-gray-30: #c1c7cd !default;
$carbon--cool-gray-40: #a2a9b0 !default;
$carbon--cool-gray-50: #878d96 !default;
$carbon--cool-gray-60: #697077 !default;
$carbon--cool-gray-70: #4d5358 !default;
$carbon--cool-gray-80: #343a3f !default;
$carbon--cool-gray-90: #21272a !default;
$carbon--cool-gray-100: #121619 !default;
$carbon--cyan-10: #e5f6ff !default;
$carbon--cyan-20: #bae6ff !default;
$carbon--cyan-30: #82cfff !default;
$carbon--cyan-40: #33b1ff !default;
$carbon--cyan-50: #1192e8 !default;
$carbon--cyan-60: #0072c3 !default;
$carbon--cyan-70: #00539a !default;
$carbon--cyan-80: #003a6d !default;
$carbon--cyan-90: #012749 !default;
$carbon--cyan-100: #061727 !default;
$carbon--gray-10: #f4f4f4 !default;
$carbon--gray-20: #e0e0e0 !default;
$carbon--gray-30: #c6c6c6 !default;
$carbon--gray-40: #a8a8a8 !default;
$carbon--gray-50: #8d8d8d !default;
$carbon--gray-60: #6f6f6f !default;
$carbon--gray-70: #525252 !default;
$carbon--gray-80: #393939 !default;
$carbon--gray-90: #262626 !default;
$carbon--gray-100: #161616 !default;
$carbon--green-10: #defbe6 !default;
$carbon--green-20: #a7f0ba !default;
$carbon--green-30: #6fdc8c !default;
$carbon--green-40: #42be65 !default;
$carbon--green-50: #24a148 !default;
$carbon--green-60: #198038 !default;
$carbon--green-70: #0e6027 !default;
$carbon--green-80: #044317 !default;
$carbon--green-90: #022d0d !default;
$carbon--green-100: #071908 !default;
$carbon--magenta-10: #fff0f7 !default;
$carbon--magenta-20: #ffd6e8 !default;
$carbon--magenta-30: #ffafd2 !default;
$carbon--magenta-40: #ff7eb6 !default;
$carbon--magenta-50: #ee5396 !default;
$carbon--magenta-60: #d12771 !default;
$carbon--magenta-70: #9f1853 !default;
$carbon--magenta-80: #740937 !default;
$carbon--magenta-90: #510224 !default;
$carbon--magenta-100: #2a0a18 !default;
$carbon--orange-40: #ff832b !default;
$carbon--purple-10: #f6f2ff !default;
$carbon--purple-20: #e8daff !default;
$carbon--purple-30: #d4bbff !default;
$carbon--purple-40: #be95ff !default;
$carbon--purple-50: #a56eff !default;
$carbon--purple-60: #8a3ffc !default;
$carbon--purple-70: #6929c4 !default;
$carbon--purple-80: #491d8b !default;
$carbon--purple-90: #31135e !default;
$carbon--purple-100: #1c0f30 !default;
$carbon--red-10: #fff1f1 !default;
$carbon--red-20: #ffd7d9 !default;
$carbon--red-30: #ffb3b8 !default;
$carbon--red-40: #ff8389 !default;
$carbon--red-50: #fa4d56 !default;
$carbon--red-60: #da1e28 !default;
$carbon--red-70: #a2191f !default;
$carbon--red-80: #750e13 !default;
$carbon--red-90: #520408 !default;
$carbon--red-100: #2d0709 !default;
$carbon--teal-10: #d9fbfb !default;
$carbon--teal-20: #9ef0f0 !default;
$carbon--teal-30: #3ddbd9 !default;
$carbon--teal-40: #08bdba !default;
$carbon--teal-50: #009d9a !default;
$carbon--teal-60: #007d79 !default;
$carbon--teal-70: #005d5d !default;
$carbon--teal-80: #004144 !default;
$carbon--teal-90: #022b30 !default;
$carbon--teal-100: #081a1c !default;
$carbon--warm-gray-10: #f7f3f2 !default;
$carbon--warm-gray-20: #e5e0df !default;
$carbon--warm-gray-30: #cac5c4 !default;
$carbon--warm-gray-40: #ada8a8 !default;
$carbon--warm-gray-50: #8f8b8b !default;
$carbon--warm-gray-60: #736f6f !default;
$carbon--warm-gray-70: #565151 !default;
$carbon--warm-gray-80: #3c3838 !default;
$carbon--warm-gray-90: #272525 !default;
$carbon--warm-gray-100: #171414 !default;
$carbon--white-0: #ffffff !default;
$carbon--yellow-20: #fdd13a !default;
$carbon--yellow-30: #f1c21b !default;
$black-100: #000000 !default;
$blue-10: #edf5ff !default;
$blue-20: #d0e2ff !default;
$blue-30: #a6c8ff !default;
$blue-40: #78a9ff !default;
$blue-50: #4589ff !default;
$blue-60: #0f62fe !default;
$blue-70: #0043ce !default;
$blue-80: #002d9c !default;
$blue-90: #001d6c !default;
$blue-100: #001141 !default;
$cool-gray-10: #f2f4f8 !default;
$cool-gray-20: #dde1e6 !default;
$cool-gray-30: #c1c7cd !default;
$cool-gray-40: #a2a9b0 !default;
$cool-gray-50: #878d96 !default;
$cool-gray-60: #697077 !default;
$cool-gray-70: #4d5358 !default;
$cool-gray-80: #343a3f !default;
$cool-gray-90: #21272a !default;
$cool-gray-100: #121619 !default;
$cyan-10: #e5f6ff !default;
$cyan-20: #bae6ff !default;
$cyan-30: #82cfff !default;
$cyan-40: #33b1ff !default;
$cyan-50: #1192e8 !default;
$cyan-60: #0072c3 !default;
$cyan-70: #00539a !default;
$cyan-80: #003a6d !default;
$cyan-90: #012749 !default;
$cyan-100: #061727 !default;
$gray-10: #f4f4f4 !default;
$gray-20: #e0e0e0 !default;
$gray-30: #c6c6c6 !default;
$gray-40: #a8a8a8 !default;
$gray-50: #8d8d8d !default;
$gray-60: #6f6f6f !default;
$gray-70: #525252 !default;
$gray-80: #393939 !default;
$gray-90: #262626 !default;
$gray-100: #161616 !default;
$green-10: #defbe6 !default;
$green-20: #a7f0ba !default;
$green-30: #6fdc8c !default;
$green-40: #42be65 !default;
$green-50: #24a148 !default;
$green-60: #198038 !default;
$green-70: #0e6027 !default;
$green-80: #044317 !default;
$green-90: #022d0d !default;
$green-100: #071908 !default;
$magenta-10: #fff0f7 !default;
$magenta-20: #ffd6e8 !default;
$magenta-30: #ffafd2 !default;
$magenta-40: #ff7eb6 !default;
$magenta-50: #ee5396 !default;
$magenta-60: #d12771 !default;
$magenta-70: #9f1853 !default;
$magenta-80: #740937 !default;
$magenta-90: #510224 !default;
$magenta-100: #2a0a18 !default;
$orange-40: #ff832b !default;
$purple-10: #f6f2ff !default;
$purple-20: #e8daff !default;
$purple-30: #d4bbff !default;
$purple-40: #be95ff !default;
$purple-50: #a56eff !default;
$purple-60: #8a3ffc !default;
$purple-70: #6929c4 !default;
$purple-80: #491d8b !default;
$purple-90: #31135e !default;
$purple-100: #1c0f30 !default;
$red-10: #fff1f1 !default;
$red-20: #ffd7d9 !default;
$red-30: #ffb3b8 !default;
$red-40: #ff8389 !default;
$red-50: #fa4d56 !default;
$red-60: #da1e28 !default;
$red-70: #a2191f !default;
$red-80: #750e13 !default;
$red-90: #520408 !default;
$red-100: #2d0709 !default;
$teal-10: #d9fbfb !default;
$teal-20: #9ef0f0 !default;
$teal-30: #3ddbd9 !default;
$teal-40: #08bdba !default;
$teal-50: #009d9a !default;
$teal-60: #007d79 !default;
$teal-70: #005d5d !default;
$teal-80: #004144 !default;
$teal-90: #022b30 !default;
$teal-100: #081a1c !default;
$warm-gray-10: #f7f3f2 !default;
$warm-gray-20: #e5e0df !default;
$warm-gray-30: #cac5c4 !default;
$warm-gray-40: #ada8a8 !default;
$warm-gray-50: #8f8b8b !default;
$warm-gray-60: #736f6f !default;
$warm-gray-70: #565151 !default;
$warm-gray-80: #3c3838 !default;
$warm-gray-90: #272525 !default;
$warm-gray-100: #171414 !default;
$white-0: #ffffff !default;
$yellow-20: #fdd13a !default;
$yellow-30: #f1c21b !default;
$carbon--colors: (
        'black': (
                100: #000000,
        ),
        'blue': (
                10: #edf5ff,
                20: #d0e2ff,
                30: #a6c8ff,
                40: #78a9ff,
                50: #4589ff,
                60: #0f62fe,
                70: #0043ce,
                80: #002d9c,
                90: #001d6c,
                100: #001141,
        ),
        'cool-gray': (
                10: #f2f4f8,
                20: #dde1e6,
                30: #c1c7cd,
                40: #a2a9b0,
                50: #878d96,
                60: #697077,
                70: #4d5358,
                80: #343a3f,
                90: #21272a,
                100: #121619,
        ),
        //'coolGray': (
        //        10: #f2f4f8,
        //        20: #dde1e6,
        //        30: #c1c7cd,
        //        40: #a2a9b0,
        //        50: #878d96,
        //        60: #697077,
        //        70: #4d5358,
        //        80: #343a3f,
        //        90: #21272a,
        //        100: #121619,
        //),
        'cyan': (
                10: #e5f6ff,
                20: #bae6ff,
                30: #82cfff,
                40: #33b1ff,
                50: #1192e8,
                60: #0072c3,
                70: #00539a,
                80: #003a6d,
                90: #012749,
                100: #061727,
        ),
        'gray': (
                10: #f4f4f4,
                20: #e0e0e0,
                30: #c6c6c6,
                40: #a8a8a8,
                50: #8d8d8d,
                60: #6f6f6f,
                70: #525252,
                80: #393939,
                90: #262626,
                100: #161616,
        ),
        'green': (
                10: #defbe6,
                20: #a7f0ba,
                30: #6fdc8c,
                40: #42be65,
                50: #24a148,
                60: #198038,
                70: #0e6027,
                80: #044317,
                90: #022d0d,
                100: #071908,
        ),
        'magenta': (
                10: #fff0f7,
                20: #ffd6e8,
                30: #ffafd2,
                40: #ff7eb6,
                50: #ee5396,
                60: #d12771,
                70: #9f1853,
                80: #740937,
                90: #510224,
                100: #2a0a18,
        ),
        'orange': (
                40: #ff832b,
        ),
        'purple': (
                10: #f6f2ff,
                20: #e8daff,
                30: #d4bbff,
                40: #be95ff,
                50: #a56eff,
                60: #8a3ffc,
                70: #6929c4,
                80: #491d8b,
                90: #31135e,
                100: #1c0f30,
        ),
        'red': (
                10: #fff1f1,
                20: #ffd7d9,
                30: #ffb3b8,
                40: #ff8389,
                50: #fa4d56,
                60: #da1e28,
                70: #a2191f,
                80: #750e13,
                90: #520408,
                100: #2d0709,
        ),
        'teal': (
                10: #d9fbfb,
                20: #9ef0f0,
                30: #3ddbd9,
                40: #08bdba,
                50: #009d9a,
                60: #007d79,
                70: #005d5d,
                80: #004144,
                90: #022b30,
                100: #081a1c,
        ),
        'warm-gray': (
                10: #f7f3f2,
                20: #e5e0df,
                30: #cac5c4,
                40: #ada8a8,
                50: #8f8b8b,
                60: #736f6f,
                70: #565151,
                80: #3c3838,
                90: #272525,
                100: #171414,
        ),
        //'warmGray': (
        //        10: #f7f3f2,
        //        20: #e5e0df,
        //        30: #cac5c4,
        //        40: #ada8a8,
        //        50: #8f8b8b,
        //        60: #736f6f,
        //        70: #565151,
        //        80: #3c3838,
        //        90: #272525,
        //        100: #171414,
        //),
        'white': (
                0: #ffffff,
        ),
        'yellow': (
                20: #fdd13a,
                30: #f1c21b,
        ),
) !default;

$carbon--hover-ui: #e5e5e5;
$carbon--active-ui: #c6c6c6;
$carbon--selected-ui: #e0e0e0;
$carbon--hover-selected-ui: #cacaca;
$carbon--disabled-1: #f4f4f4;
$carbon--disabled-2: #c6c6c6;
$carbon--disabled-3: #8d8d8d;

// tn color

$sv-color--primary: #0d6b68;
$sv-color--primary-alt: #0d6b68;
$sv-color--secondary: #F2C933;
$sv-color--text-common: $carbon--gray-70;
$sv-color--text-1: $carbon--gray-100;
$sv-color--text-2: $carbon--gray-90;
$sv-color--text-3: $carbon--gray-80;
$sv-color--text-4: $carbon--gray-70;

// tn color mapping

$sv--color-mapping: (
    "transparent": transparent,
    primary: $sv-color--primary,
    'primary-alt': $sv-color--primary-alt,
    secondary: $sv-color--secondary,
    secondary-alt: #fab902,
    secondary-alt-2: #fa8002,
    text-common: $sv-color--text-common,
    text-1: $sv-color--text-1,
    text-2: $sv-color--text-2,
    text-3: $sv-color--text-3,
    hover-ui: $carbon--hover-ui,
);
