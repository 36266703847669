@each $name, $color in $sv--color-mapping {
    .sv-color--#{$name},
    .sv-color--#{$name}--hover:not(.-activated):hover,
    .sv-color--#{$name}--active:not(.-activated):active,
    .sv-color--#{$name}--activated.-activated {
        color: $color;
    }

    .sv-bgc--#{$name},
    .sv-bgc--#{$name}--hover:not(.-activated):hover,
    .sv-bgc--#{$name}--active:not(.-activated):active,
    .sv-bgc--#{$name}--activated.-activated {
        background-color: $color;
    }

    .sv-color-trigger {
        &:not(.-activated):hover {
            .sv-color--#{$name}--triggered-hover {
                color: $color;
            }
        }

        &:not(.-activated):active {
            .sv-color--#{$name}--triggered-active {
                color: $color;
            }
        }

        &.-activated {
            .sv-color--#{$name}--triggered-activated {
                color: $color;
            }
        }
    }

    .sv-bgc-trigger {
        &:not(.-activated):hover {
            .sv-bgc--#{$name}--triggered-hover {
                background-color: $color;
            }
        }

        &:not(.-activated):active {
            .sv-bgc--#{$name}--triggered-active {
                background-color: $color;
            }
        }

        &.-activated {
            .sv-bgc--#{$name}--triggered-activated {
                background-color: $color;
            }
        }
    }
}
