.sv-heading-1 {
    display: flex;
    align-items: baseline;
}

.sv-heading-1__label {
    font-weight: 500;
    color: $sv-color--text-1;
    margin-right: 8px;
}

.sv-heading-1__line {
    flex: 1;
    height: 1px;
    background-color: $carbon--gray-20;
}

.sv-heading-1__right-side {
    align-self: center;
}
