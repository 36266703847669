/**
 * Container
 */

.sv-search-box {
    position: relative;
}

/**
 * input
 */

.sv-search-box__input {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: $carbon--gray-10;
    color: $carbon--gray-100;
    transition: background-color $sv-transition-short-time $sv-transition-effect-common, outline $sv-transition-short-time $sv-transition-effect-common;
    border: none;
    width: 100%;
    border-bottom: 1px solid $carbon--gray-50;
    padding: 0 40px;
    @include placeholder($carbon--gray-50);

    &:focus {
        outline-color: $sv-color--primary;
    }
}

/**
 * input
 */

.sv-search-box__search-icon,
.sv-search-box__clear-icon,
.sv-search-box__adv-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;
    height: 100%;
    top: 0;
    color: $carbon--gray-80;
}

.sv-search-box__search-icon {
    pointer-events: none;
}

.sv-search-box__clear-icon,
.sv-search-box__adv-toggle {
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: -2px;
    transition: opacity $sv-transition-short-time $sv-transition-effect-common, background-color $sv-transition-short-time $sv-transition-effect-common, outline $sv-transition-short-time $sv-transition-effect-common, border $sv-transition-short-time $sv-transition-effect-common;

    &:after {
        content: '';
        position: absolute;
        height: calc(100% - 3px);
        border-left: 2px solid $carbon--gray-10;
        top: 2px;
        width: 100%;
        transition: border $sv-transition-short-time $sv-transition-effect-common;
    }

    &:hover {
        background-color: $carbon--hover-ui;
        border-bottom-color: $carbon--gray-50;

        &:after {
            border-left-color: $carbon--hover-ui;
        }
    }

    &:active {
        background-color: $carbon--selected-ui;
    }

    &:active, &:focus {
        outline-color: $sv-color--primary;

        &:after {
            border-left-color: $sv-color--primary;
        }
    }
}

.sv-search-box__clear-icon {
    visibility: hidden;
    opacity: 0;
}

.sv-search-box__input:focus ~ .sv-search-box__clear-icon:hover,
.sv-search-box__input:focus ~ .sv-search-box__adv-toggle:hover {
    outline-color: $sv-color--primary;
}

// when show clear
.sv-search-box.-show-clear {
    .sv-search-box__clear-icon {
        visibility: visible;
        opacity: 1;
    }
}

// has search adv
.sv-search-box.-has-search-adv {
    .sv-search-box__clear-icon {
        &:after {
            border-right: 2px solid $carbon--gray-10;
        }

        &:hover:after {
            border-right-color: $carbon--hover-ui;
        }

        &:active:after, &:focus:after {
            border-right-color: $sv-color--primary;
        }
    }

    .sv-search-box__adv-toggle {
        right: 0;
    }
}

/**
 * Size
 */

// small
.sv-search-box.-size-small {
    .sv-search-box__input {
        font-size: 14px;
        height: 32px;
        line-height: 18px;
        letter-spacing: 0.16px;
    }

    .sv-search-box__search-icon,
    .sv-search-box__clear-icon,
    .sv-search-box__adv-toggle {
        width: 32px;
        font-size: 16px;
    }

    .sv-search-box__search-icon {
        left: 4px;
    }

    .sv-search-box__clear-icon {
        right: 0;
    }

    &.-has-search-adv {
        &.-show-clear {
            .sv-search-box__input {
                padding-right: 72px;
            }
        }

        .sv-search-box__clear-icon {
            right: 32px;
        }
    }
}

// large
.sv-search-box.-size-large {
    .sv-search-box__input {
        font-size: 16px;
        height: 48px;
        line-height: 22px;
        padding: 0 64px 0 48px;
    }

    .sv-search-box__search-icon,
    .sv-search-box__clear-icon,
    .sv-search-box__adv-toggle {
        width: 48px;
        font-size: 20px;
    }

    .sv-search-box__search-icon {
        left: 0;
    }

    .sv-search-box__clear-icon {
        right: 0;
    }

    &.-has-search-adv {
        .sv-search-box__input {
            padding-right: 112px;
        }

        .sv-search-box__clear-icon {
            right: 48px;
        }
    }
}

/**
 * Disabled
 */

.sv-search-box.-disabled {
    .sv-search-box__input,
    .sv-search-box__search-icon,
    .sv-search-box__clear-icon,
    .sv-search-box__adv-toggle {
        cursor: not-allowed;
        color: $carbon--disabled-2;
    }

    .sv-search-box__input {
        border-bottom-color: #f4f4f4;
        @include placeholder($carbon--disabled-2);
    }

    .sv-search-box__clear-icon,
    .sv-search-box__adv-toggle {
        background-color: transparent;
        border-color: transparent;
        outline: none;

        &:after {
            display: none;
        }
    }
}
