/*------------------------------------
  GRID VIEW 1
//------------------------------------*/
.sv-data-grid-1-item {
    background-color: $carbon--gray-10;
    position: relative;
    outline: 2px solid transparent;
    outline-offset: -2px;
    @include easing-animation-flash(outline);
}

.sv-data-grid-1-item__top-content,
.sv-data-grid-1-item__bottom-content {
    display: flex;
}

.sv-data-grid-1-item__top-content-left-block,
.sv-data-grid-1-item__top-content-right-block {
    flex: 1;
}

.sv-data-grid-1-item__top-content {
    border-bottom: 1px solid $carbon--gray-20;
}

.sv-data-grid-1-item__top-content-left-block {
    max-width: 112px;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sv-data-grid-1-item__top-content-right-block {
    padding-top: 24px;
    padding-right: 16px;
    padding-bottom: 12px;
}

.sv-data-grid-1-item__bottom-content-left-block,
.sv-data-grid-1-item__bottom-content-right-block {
    padding: 8px 16px;
    display: flex;
    min-height: 48px;
    align-items: center;
}

.sv-data-grid-1-item__bottom-content-left-block {
    flex: 5.34;
    justify-content: flex-end;
    border-right: 1px solid $carbon--gray-20;
}

.sv-data-grid-1-item__bottom-content-right-block {
    flex: 4.66;
}

.sv-data-grid-1-item__meta-item {
    display: flex;

    & + & {
        margin-top: 4px;
    }
}

.sv-data-grid-1-item__meta-item-label {
    min-width: 72px;
    padding-right: 8px;
}

.sv-data-grid-1-item__meta-item-value {
    flex: 1;
    font-weight: 500;
}

.sv-data-grid-1-item__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($carbon--gray-100, 0.6);
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 100%;
    opacity: 0;
    @include easing-animation-flash(opacity);

    &:hover {
        opacity: 1;
    }

    .sv-btn {
        flex: 1;
        height: 48px;
        line-height: 48px;
        justify-content: center;
    }
}

.sv-data-grid-1-item__unchecked-icon,
.sv-data-grid-1-item__checked-icon {
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    top: 12px;
    right: 12px;
    font-size: 16px;
    color: $sv-color--primary;
    cursor: pointer;
    display: none;
}

.sv-data-grid-1-item__unchecked-icon {
    display: flex;
    color: #fff;
}

.sv-data-grid-1-item.-activated {
    outline-color: $sv-color--primary;

    .sv-data-grid-1-item__checked-icon {
        display: flex;
    }

    .sv-data-grid-1-item__overlay {
        display: none;
    }
}

/*------------------------------------
  LIST VIEW 1
//------------------------------------*/

.sv-data-list-1-row {
    background-color: $carbon--gray-10;
    display: flex;
    padding-right: 28px;
    position: relative;
    outline: 2px solid transparent;
    outline-offset: -2px;
    @include easing-animation-flash(outline);

    & + & {
        margin-top: 8px;
    }
}

.sv-data-list-1-cell {
    display: flex;
    min-height: 64px;
    align-items: center;
    padding: 4px 8px;
}

.sv-data-list-1-row__unchecked-icon,
.sv-data-list-1-row__checked-icon {
    position: absolute;
    top: 50%;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    right: 8px;
    font-size: 16px;
    color: $sv-color--primary;
    cursor: pointer;
    display: none;
}

.sv-data-list-1-row__unchecked-icon {
    display: flex;
    color: #fff;
}

.sv-data-list-1-row__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($carbon--gray-100, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 28px;
    opacity: 0;
    @include easing-animation-flash(opacity);

    &:hover {
        opacity: 1;
    }
}

.sv-data-list-1-row.-activated {
    outline-color: $sv-color--primary;

    .sv-data-list-1-row__checked-icon {
        display: flex;
    }

    .sv-data-list-1-row__overlay {
        display: none;
    }
}

