* {
    @include box-sizing(border-box);
}

*:before,
*:after {
    @include box-sizing(border-box);
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $carbon--gray-20;
}

::-webkit-scrollbar-track {
    background-color: $carbon--gray-20;
}

::-webkit-scrollbar-thumb {
    background-color: $carbon--gray-30;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
}

body {
    font-family: 'Helvetica Neue';
    font-size: 14px;
    letter-spacing: 0.16px;
    margin: 0;
    line-height: 1.5;
    overflow: hidden;
    color: $sv-color--text-common;
}

body.landing-body {
    background-color: #fff;
}

p, h1, h2, h3, h4, h5, h6, ul {
    margin-top: 0;
    margin-bottom: 20px;
}

input, textarea, button, select {
    outline:     none;
    font-family: inherit;
    font-size:   inherit;
    line-height: inherit;
}

a {
    outline: none;
    text-decoration: none;
}

body .ui-widget, body .ui-widget .ui-widget {
    font-family: inherit;
}

th, td {
    vertical-align: top;
    text-align: left;
}

ol, ul {
    list-style: none;
}
