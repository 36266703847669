.sv-side-overlay.sv-side-overlay {
    padding: 0;
    display: flex;
    flex-direction: column;
    @include easing-animation-fast(transform);

    .ui-sidebar-close {
        float: none;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        background-color: $carbon--gray-10;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        color: $sv-color--text-1;
        border-radius: 0;
        @include easing-animation-flash();

        span {
            font-family: "carbon" !important;
            font-size: 16px;

            &:before {
                content: "\e046";
            }
        }

        &:hover {
            background-color: $carbon--gray-20;
        }
    }
}

.sv-side-overlay__header-area {
    padding-left: 32px;
    padding-right: 32px;
    border-bottom: 1px solid $carbon--gray-20;
}

.sv-side-overlay__title {
    padding-top: 14px;
    padding-bottom: 20px;
    color: $sv-color--text-2;
    font-size: 20px;
}

.sv-side-overlay__body-area {
    flex: 1;
}

.sv-side-overlay__footer-area {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $carbon--gray-20;
    padding: 24px 32px;
}
