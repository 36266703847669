/*------------------------------------
  MODAL
//------------------------------------*/

.sv-modal {
    &.force-full-width {
        max-width: 100% !important;
    }

    &.ui-dialog-maximized {
        .ui-dialog-content {
            max-height: none !important;
        }
    }

    &:not(.ui-dialog-maximized) {
        .ui-dialog-content {
            height: auto !important;
        }
    }

    .ui-dialog {
        background-color: #ffffff;
        border-radius: 0;
    }

    .ui-dialog-content.ui-dialog-content {
        border: none;
        padding: 0;
    }

    .ui-dialog-titlebar.ui-dialog-titlebar {
        border: none;
        border-bottom: 1px solid $carbon--gray-20;
        padding: 15px 32px 16px;
        font-weight: normal;
        background-color: #fff;
    }

    /*3x*/
    .ui-dialog-title.ui-dialog-title.ui-dialog-title {
        line-height: 32px;
        font-size: 20px;
        font-weight: 500;
        color: $carbon--gray-90;
    }

    /*3x*/
    .ui-dialog-titlebar-icon.ui-dialog-titlebar-icon.ui-dialog-titlebar-icon {
        font-size: 18px;
        height: 32px;
        width: 32px;
        position: absolute;
        top: 0;
        background-color: $carbon--gray-10;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $sv-color--text-common;
        border-radius: 0;

        &.ui-dialog-titlebar-close {
            right: 0;
        }

        &.ui-dialog-titlebar-maximize {
            right: 30px;
        }

        &:hover {
            background-color: $carbon--hover-ui;
        }
    }

    /**
     * Footer
     */

    .ui-dialog-footer.ui-dialog-footer {
        border: none;
        border-top: 1px solid $carbon--gray-20;
        padding: 24px 32px 16px 32px;
    }
}

.sv-modal-footer-content {
    .sv-btn {
        margin-bottom: 8px;
    }
}

.sv-modal-footer-content__left-block {
    text-align: left;
}

.sv-modal-footer-content__left-block .sv-btn {
    margin-right: 8px;
}

.sv-modal-footer-content__right-block .sv-btn {
    margin-right: 0;
    margin-left: 8px;
}

.sv-modal-content-panel {
    padding-left: 32px;
    padding-right: 32px;

    &.sv-form-container {
        padding-top: 24px;
        padding-bottom: 8px;
    }
}

/*------------------------------------
  TAB
//------------------------------------*/

.sv-modal.modal-with-tabs {
    .ui-dialog-content {
        overflow: hidden;
    }
}

/*p-tabView*/
.sv-modal-tabview {
    .ui-tabview-nav.ui-tabview-nav.ui-tabview-nav {
        padding: 0 40px;
    }

    p-tabPanel, .ui-tabview-panel {
        display: block;
        height: 100%;
    }
}

.sv-modal-tab-panel-content {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
}

