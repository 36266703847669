sv-breadcrumb {
    display: block;

    .bx--breadcrumb {
        display: flex;
        flex-wrap: wrap;
        height: 20px;
    }

    .bx--breadcrumb-item {
        margin-right: 8px;
    }

    .bx--breadcrumb-item:after {
        content: "/";
        margin-left: 16px;
    }

    .bx--link {
        color: $sv-color--text-common;
        padding-bottom: 2px;
        @include easing-animation-flash();

        &:hover {
            border-bottom: 1px solid $sv-color--text-common;
        }

        &:focus {
            border-bottom: 3px solid $sv-color--text-common;
        }
    }

    .bx--breadcrumb-item:last-of-type .bx--link {
        font-weight: 700;
    }

    .bx--breadcrumb.bx--breadcrumb--no-trailing-slash {
        .bx--breadcrumb-item:last-of-type:after {
            content: "";
        }
    }
}

.sv-breadcrumb-dropdown .bx--link {
    color: $sv-color--text-common;
}


