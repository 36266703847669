.sv-tree {
    /**
     * Icon
     */

    .pi {
        font-family: "carbon" !important;
    }

    .pi-caret-right:before {
        content: "\e038";
    }

    .pi-caret-down:before {
        content: "\e031";
    }

    .pi-minus:before {
        content: "";
        position: absolute;
        display: block;
        font-weight: bold;
        width: 8px;
        border-bottom: 2px solid;
        left: 3px;
        top: 6px;
    }

    .pi-check:before {
        content: "";
        position: absolute;
        display: block;
        width: 9px;
        height: 5px;
        left: 3px;
        top: 3px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
    }

    /**
     * General
     */

    .ui-tree {
        color: inherit;
        border: 0;
        padding: 0;
    }

    /*2x*/
    .ui-tree-container.ui-tree-container {
        padding: 0;
    }

    /*3x*/
    .ui-treenode.ui-treenode.ui-treenode {
        padding: 0;
    }

    /*4x*/
    .ui-treenode-content.ui-treenode-content.ui-treenode-content.ui-treenode-content {
        border: 0;
        outline: none;
    }

    .ui-treenode-content {
        display: flex;
        align-items: center;
        @include easing-animation(background-color);

        &:hover {
            background-color: $carbon--gray-10;
        }
    }

    /*5x*/
    .ui-treenode-icon.ui-treenode-icon.ui-treenode-icon.ui-treenode-icon.ui-treenode-icon {
        color: $sv-color--primary;
        font-size: 20px;
        margin-right: 8px;
    }

    /**
     * Toggle
     */

    /*5x*/
    .ui-tree-toggler.ui-tree-toggler.ui-tree-toggler.ui-tree-toggler.ui-tree-toggler {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        margin-right: 0;
    }

    /**
     * Checkbox
     */

    .ui-chkbox {
        height: 16px;
        width: 16px;
        min-width: 16px;
    }

    .ui-chkbox-box.ui-chkbox-box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: 0;
    }

    .ui-chkbox-icon {
        display: block;
        line-height: 14px;
        width: 100%;
        height: 100%;
        color: $sv-color--text-1;
        border: 1px solid $sv-color--text-1;
    }

    .ui-chkbox-icon.pi-check {
        background-color: $sv-color--primary;
        color: #fff;
        border-color: $sv-color--primary;
    }

    /*5x*/
    .ui-chkbox.ui-chkbox.ui-chkbox.ui-chkbox.ui-chkbox {
        margin-right: 8px;
    }

    /*6x*/
    .ui-chkbox-icon.ui-chkbox-icon.ui-chkbox-icon.ui-chkbox-icon.ui-chkbox-icon.ui-chkbox-icon {
        margin: 0;
    }

    /**
     * Label
     */

    .ui-treenode-label {
        color: $sv-color--text-3;
    }

    /*5x*/
    .ui-treenode-label.ui-treenode-label.ui-treenode-label.ui-treenode-label.ui-treenode-label {
        padding: 4px 0;
    }

    /*4x*/
    .ui-treenode-content.ui-treenode-content.ui-treenode-content.ui-treenode-content .ui-treenode-label.ui-state-highlight,
    .ui-treenode-content.ui-treenode-content.ui-treenode-content.ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
        background-color: transparent;
        color: $sv-color--text-3;
    }

    .ui-tree-container > p-treeNode > li > .ui-treenode-content > .ui-treenode-label {
        font-weight: 700;
    }

    // for single selection mode

    &[selectionmode="single"] {
        .ui-treenode-content.ui-treenode-content-selected {
            background-color: $carbon--hover-ui;
        }
    }
}
