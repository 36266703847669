/*------------------------------------
  GENERAL
//------------------------------------*/

.sv-page-content-area-wrapper {
    position: relative;
    flex: 1 1;
}

.sv-page-content-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/*------------------------------------
  HEADER
//------------------------------------*/

.sv-page-content-area__header {
    padding: 0 16px;
}

// breadcrumb
.sv-page-breadcrumb-area {
    padding: 10px 0;
    border-bottom: 1px solid $carbon--gray-20;
}

/**
 * Page title
 */

.sv-page-title-area {
    padding-top: 24px;
    padding-bottom: 8px;
    display: flex;

    &__left-block, &__right-block {
        margin-bottom: 16px;
    }

    &__left-block {
        flex: 1 1;
        min-width: 0;
        padding-right: 16px;
        display: flex;
    }
}

.sv-page-title {
    font-size: 20px;
    line-height: 32px;
    color: $sv-color--text-2;
    position: relative;
    padding-right: 24px;
    margin-bottom: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;

    &__helper {
        position: absolute;
        color: $carbon--blue-60;
        font-size: 16px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: block;
        top: 4px;
        right: 0;
        cursor: pointer;
    }
}

// search box
.sv-page-content-area__header .sv-search-box {
    width: 320px;
}

/*------------------------------------
  BODY
//------------------------------------*/

.sv-page-content-area__body {
    flex: 1 1;
    padding: 16px;
    overflow: hidden;
}

.sv-page-content-area__header + .sv-page-content-area__body {
    padding-top: 0;
}
