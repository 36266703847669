/*------------------------------------
  ACCORDION 1 (https://prnt.sc/qff109)
//------------------------------------*/

.sv-accordion-1 {
    .ui-accordion-header.ui-accordion-header {
        border-radius: 0;
        margin: 0;

        a {
            font-weight: normal;
            padding: 12px 20px 12px 0;
            position: relative;
        }
    }

    /*6x*/
    .ui-accordion-header.ui-accordion-header.ui-accordion-header.ui-accordion-header.ui-accordion-header.ui-accordion-header {
        a {
            color: inherit;
            background-color: transparent;
            border: 0;
        }
    }

    .ui-accordion-toggle-icon.ui-accordion-toggle-icon {
        position: absolute;
        font-size: 16px;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &:before {
            position: relative;
            top: 3px;
        }
    }

    /*6x*/
    .ui-accordion-toggle-icon.ui-accordion-toggle-icon.ui-accordion-toggle-icon.ui-accordion-toggle-icon.ui-accordion-toggle-icon.ui-accordion-toggle-icon {
        color: $sv-color--text-1;
    }

    .ui-accordion-content.ui-accordion-content {
        background-color: transparent;
        border: 0;
        padding: 0;
    }
}
