.sv-tabview {
    display: block;

    .ui-tabview {
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .ui-tabview-panels.ui-tabview-panels {
        padding: 0;
        border: none;
        flex: 1 1;
        overflow: hidden;
    }

    .ui-tabview-nav.ui-tabview-nav.ui-tabview-nav {
        background: $carbon--selected-ui;
        border-radius: 0;
        margin: 0;
    }

    .ui-tabview-title {
        line-height: 22px;
    }

    /*6x*/
    .ui-tabview-nav.ui-tabview-nav.ui-tabview-nav.ui-tabview-nav.ui-tabview-nav.ui-tabview-nav li {
        background: none;
        border: 0;
        outline: none;
        position: static;
        margin-bottom: 0;

        a {
            color: $sv-color--text-common;
            font-weight: normal;
            padding: 8px 16px;
            display: inline-block;
            border-bottom: 2px solid transparent;
            @include easing-animation-flash();
        }

        &:hover a {
            color: $sv-color--text-1;
            border-color: $carbon--gray-60;
        }

        &.ui-state-active {
            border-color: $carbon--selected-ui;
            background: none;

            a {
                color: $sv-color--text-1;
                font-weight: 600;
                border-color: $sv-color--primary;
            }

            &:hover, &:focus {
                outline: none;
            }
        }
    }
}


/*------------------------------------
  Custom Tabs
//------------------------------------*/

.sv-custom-tabview-nav {
    margin: 0;
    padding: 0;
    background-color: #fff;
    background: $carbon--selected-ui;
    list-style: none;
    display: flex;
}

.sv-custom-tabview-nav-item {
    border-color: $carbon--selected-ui;
    display: block;

    & + & {
        margin-left: 2px;
    }
}

.sv-custom-tabview-nav-item-label {
    color: $sv-color--text-common;
    padding: 8px 16px;
    display: inline-block;
    line-height: 22px;
    border-bottom: 2px solid transparent;
    @include easing-animation-flash();
}

.sv-custom-tabview-nav-item:hover .sv-custom-tabview-nav-item-label {
    color: $sv-color--text-1;
    border-color: $carbon--gray-60;
}

.sv-custom-tabview-nav-item.-activated .sv-custom-tabview-nav-item-label {
    color: $sv-color--text-1;
    font-weight: 600;
    border-color: $sv-color--primary;
}

