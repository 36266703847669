.sv-notification-group-label {
    line-height: 20px;
    padding: 10px 16px;
    font-weight: bold;
    background-color: $carbon--gray-10;
}

.sv-notification-item {
    border-bottom: 1px solid $carbon--gray-20;
    padding: 12px 16px 12px 56px;
    position: relative;
    cursor: pointer;
    @include easing-animation(background-color);

    &__icon {
        background-color: $carbon--gray-10;
        width: 32px;
        height: 32px;
        color: $sv-color--primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 16px;
        left: 16px;
        font-size: 16px;
    }

    &__title {
        color: $sv-color--text-3;
        margin-bottom: 8px;
    }

    &.-unseen &__title {
        font-weight: 700;
    }

    &:hover {
        background-color: $carbon--hover-ui;
    }
}
