.sv-avatar {
    position: relative;
}

.sv-avatar__img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.sv-avatar__gender-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.-male {
        color: #fff;
        background-color: $sv-color--primary;
    }

    &.-female {
        color: $sv-color--primary;
        background-color: $sv-color--secondary;
    }
}


.sv-avatar.-size-1 {
    width: 48px;
    height: 48px;

    .sv-avatar__gender-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
    }
}

.sv-avatar.-size-2 {
    width: 64px;
    height: 64px;

    .sv-avatar__gender-icon {
        width: 20px;
        height: 20px;
        font-size: 16px;
    }
}
