.sv-link {
    line-height: 18px;
    text-decoration: none;
    color: $carbon--blue-60;
    @include easing-animation-flash();

    &:not(.-disabled):hover {
        color: $carbon--blue-60;
        text-decoration: underline;
    }

    &:not(.-disabled):focus {
        outline: 1px solid $carbon--blue-60;
    }

    &:not(.-disabled):visited {
        color: $carbon--purple-60;
    }

    &:not(.-disabled):visited:hover {
        color: $sv-color--primary;
    }

    &:not(.-disabled):active, &:not(.-disabled):active:visited, &:not(.-disabled):active:visited:hover {
        color: $carbon--gray-100;
        text-decoration: underline;
    }

    &.-disabled {
        cursor: not-allowed;
        color: $carbon--disabled-2;
    }
}
