/*------------------------------------
  LEFT MENU AREA
//------------------------------------*/

.sv-page-menu-area {
    padding-top: $sv-topbar-height;
    position: fixed;
    z-index: 99;
    height: 100vh;
    overflow: hidden;
    display: flex;
    width: $sv-left-menu-width;
    flex-direction: column;
    top: 0;
    left: 0;
    border-right: 1px solid $carbon--cool-gray-20;
    background-color: $carbon--gray-10;
}

/*------------------------------------
  SEARCH BOX
//------------------------------------*/

.sv-main-menu-search-box-container {
    border-bottom: 1px solid $carbon--cool-gray-20;
}

.sv-main-menu-search-box {
    position: relative;

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 100%;
        z-index: 2;
        color: $sv-color--primary;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }

    &__input {
        height: 48px;
        display: block;
    }
}

.sv-main-menu-search-box__input {
    .ui-autocomplete {
        display: block;
        width: 100%;
        height: 100%;
    }

    .ui-inputtext.ui-inputtext {
        padding-left: 56px;
        padding-right: 40px;
        width: 100%;
        height: 100%;
        outline: none;
        border: 0;
        background-color: $carbon--gray-10;
    }

    .ui-autocomplete-panel {
        border-radius: 0;
        border: 1px solid $carbon--gray-20;
        box-shadow: 0 2px 4px 0 $carbon--gray-20;
    }

    .ui-autocomplete-list-item.ui-autocomplete-list-item.ui-autocomplete-list-item {
        color: inherit;
        line-height: 20px;
        padding: 7px 16px;

        &.ui-state-highlight {
            color: inherit;
            background-color: $carbon--selected-ui;
        }

        mark {
            background: transparent;
            font-weight: bold;
            color: $sv-color--primary;
        }
    }
}

/*------------------------------------
  MENU
//------------------------------------*/

.sv-page-menu-wrapper {
    flex: 1 1;
    overflow: hidden;

    &__overflow-panel {
        width: $sv-left-menu-width - 1px;
        height: 100%;
        overflow-y: auto;
    }
}

.sv-page-menu, .sv-page-submenu {
    padding: 0;
    margin: 0;
    list-style: none;
}

/*ul*/
.sv-page-submenu {
    overflow: hidden;
}

/*a*/
.sv-page-menu-item-link {
    color: inherit;
    cursor: pointer;
    position: relative;
    display: block;
    line-height: 20px;

    &__label {
        display: inline-block;
    }
}

/**
 * Menu level 0 (Category)
 */

.sv-page-menu-item--level-0 {
    > .sv-page-menu-item-link {
        background-color: $carbon--gray-20;
        text-transform: uppercase;
        font-weight: 700;
        color: $sv-color--text-3;
        padding: 10px 16px;
    }
}

/**
 * Menu level 1
 */

.sv-page-submenu--level-1 {
    height: auto !important;
}

.sv-page-submenu--level-1 .sv-page-menu-item {
    > .sv-page-menu-item-link {
        padding-left: 56px;
        padding-right: 16px;
        @include easing-animation();
    }

    > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon {
        display: none;
    }

    &:not(.-activated) {
        > .sv-page-menu-item-link:hover {
            color: $sv-color--text-3;
            background-color: $carbon--hover-ui;
        }
    }

    &.-activated {
        > .sv-page-menu-item-link {
            color: $sv-color--text-3;
            background-color: $carbon--selected-ui;
        }
    }

    &.-has-submenu {
        > .sv-page-menu-item-link {
            padding-right: 40px;
        }

        > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon {
            position: absolute;
            width: 40px;
            height: 100%;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            &:before {
                @include easing-animation();
            }
        }
    }

    &.-has-submenu.-activated {
        > .sv-page-menu-item-link .sv-page-menu-item-link__toggle-icon:before {
            transform: rotate(90deg);
        }
    }
}

.sv-page-menu-item--level-1 {
    > .sv-page-menu-item-link {
        font-weight: 700;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    > .sv-page-menu-item-link .sv-page-menu-item-link__main-icon {
        position: absolute;
        width: 48px;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $sv-color--primary;
        @include easing-animation();
    }

    &.-activated {
        > .sv-page-menu-item-link .sv-page-menu-item-link__main-icon {
            color: #fff;
            background-color: $sv-color--primary;
        }
    }
}

/**
 * Menu level 2
 */

.sv-page-submenu--level-2 .sv-page-menu-item-link {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sv-page-submenu--level-2 .sv-page-menu-item:not(.has-submenu) {
    > .sv-page-menu-item-link {
        padding-left: 52px;
        border-left: 4px solid transparent;
    }

    &.-activated > .sv-page-menu-item-link {
        border-color: $sv-color--primary;
    }
}

.sv-page-menu-item--level-2 {
    > .sv-page-menu-item-link {
        font-weight: 700;
    }

    &.-has-submenu.-activated {
        > .sv-page-menu-item-link {
            background-color: transparent;
        }
    }
}

// menu level 3
.sv-page-menu-item--level-3 {
    > .sv-page-menu-item-link .sv-page-menu-item-link__label {
        position: relative;
        padding-left: 12px;

        &:before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: $sv-color--primary;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}



