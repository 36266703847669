.sv-ol, .sv-ul {
    line-height: 18px;
    margin: 0;

    &.-nested {
        --space: 0;
        margin-left: 24px;
        margin-top: 4px;
    }
}

.sv-ol {
    list-style-type: decimal;

    &.-nested {
        list-style-type: lower-latin;
    }
}

.sv-li {
    color: $sv-color--text-1;
    margin-bottom: 4px;
}

.sv-ul > .sv-li {
    position: relative;

    &:before {
        position: absolute;
        left: -16px;
        content: "\2013";
    }
}

.sv-ul.-nested > .sv-li {
    &:before {
        left: -12px;
        content: "\25AA";
    }
}
