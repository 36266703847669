$sv-background-login: #f5f5f5;

/*------------------------------------
  TOPBAR
//------------------------------------*/

.sv-page-login {
    background-color: $sv-background-login;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.sv-page-login-content-area {
    background-color: #fff;
    width: 498px;
    padding: 50px 49px 46px;
}

/**
 * Header
 */

.sv-page-login-content-area__header {
    border-bottom: 1px solid $carbon--gray-20;
    padding-bottom: 37px;
    display: flex;
}

// logo
.sv-page-login-logo {
    width: 54px;
    height: 54px;
}

// logo accessory
.sv-page-login-logo-accessory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 17px;

    &__title {
        font-weight: 700;
        color: $carbon--gray-70;
        font-size: 14px;
        letter-spacing: 0.16px;
    }

    &__slogan {
        font-weight: 400;
        font-size: 24px;
        padding-top: 2px;
        color: $carbon--gray-90;
    }
}

/**
 * Body
 */

.sv-page-login-content-area__body {
    padding-top: 36px;
    padding-bottom: 32px;

    .sv-form-item {
        padding-bottom: 24px;

        .sv-form-item__message-container {
            display: none;
        }

        &.-error {
            padding-bottom: 2px;

            .sv-form-item__message-container {
                display: block;
            }
        }
    }
}

.sv-page-login-password-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px;
    margin-bottom: 28px;
}

/**
 * Footer
 */

.sv-page-login-content-area__footer {
    border-top: 1px solid $carbon--gray-20;
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

