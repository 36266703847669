.h-easing {
    &, &--flash, &--short, &--long {
        transition-timing-function: $sv-transition-effect-common;
    }

    transition-duration: $sv-transition-time;

    &--flash {
        transition-duration: $sv-transition-flash-time;
    }

    &--short {
        transition-duration: $sv-transition-short-time;
    }

    &--long {
        transition-duration: $sv-transition-long-time;
    }
}
