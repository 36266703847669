// Variable
// General
// Text field
// Textarea
// Number
// Toggle
// Slider
// Checkbox
// Radio
// Date picker
// Dropdown

/*------------------------------------
  VARIABLE
//------------------------------------*/

$sv-datepicker-range-date: #e4e0f1;

/*------------------------------------
  GENERAL
//------------------------------------*/

.sv-form-item {
    line-height: 18px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-start;

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    textarea {
        @include placeholder($carbon--gray-50);
    }
}

.sv-form-item__label {
    font-size: 12px;
    letter-spacing: .32px;
    line-height: 16px;
    color: $sv-color--text-common;
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: 8px;
    pointer-events: none;
}

.sv-form-item__input-wrapper {
    position: relative;
    display: flex;
    width: 100%;
}

.sv-form-item__input {
    width: 100%;
}

.sv-form-item__message-container {
    margin-top: 4px;
}

.sv-form-item__message {
    color: $carbon--red-60;
    line-height: 1.5;
    font-size: 12px;
}

// required
.sv-form-item.-required {
    .sv-form-item__label:after {
        content: ' (*)';
    }
}

// error
.sv-form-item.-error {
    .sv-form-item__input-wrapper:before {
        content: "\e2cb";
        font-family: "carbon" !important;
        color: $carbon--red-60;
        position: absolute;
        font-size: 16px;
        height: 16px;
        width: 16px;
        line-height: 16px;
        display: none;
    }
}

// disabled
.sv-form-item.-disabled {
    .sv-form-item__label {
        color: $carbon--disabled-2;
    }

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    textarea {
        color: $carbon--disabled-2;
        @include placeholder($carbon--disabled-2);
    }
}

.sv-form-container {
    .sv-form-item {
        margin-bottom: 32px;
    }

    .sv-form-item.-error {
        margin-bottom: 10px;
    }
}

/*------------------------------------
  TEXT FIELD
//------------------------------------*/

.sv-text-field {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .16px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: $carbon--gray-10;
    height: 40px;
    padding: 0 16px;
    color: $sv-color--text-1;
    border: none;
    border-bottom: 1px solid $carbon--gray-50;
    transition: background-color $sv-transition-flash-time $sv-transition-effect-common,
    outline $sv-transition-flash-time $sv-transition-effect-common;

    &:active, &:focus {
        outline-color: $sv-color--primary;
    }
}

// error
.sv-form-item.-error {
    .sv-form-item__input-wrapper.-type-text-field:before {
        display: block;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 16px;
    }

    .sv-text-field {
        outline-color: $carbon--red-60;
        padding-right: 40px;
    }
}

// disabled
.sv-form-item.-disabled {
    .sv-text-field:disabled {
        cursor: not-allowed;
        outline: none;
        background-color: $carbon--gray-10;
        border-bottom: 1px solid transparent;
    }
}

/*------------------------------------
  TEXTAREA
//------------------------------------*/
.sv-textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .16px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: $carbon--gray-10;
    min-height: 40px;
    padding: 11px 16px;
    color: $sv-color--text-1;
    order: 3;
    resize: vertical;
    border: none;
    border-bottom: 1px solid $carbon--gray-50;
    transition: background-color $sv-transition-flash-time $sv-transition-effect-common,
    outline $sv-transition-flash-time $sv-transition-effect-common;

    &:active, &:focus {
        outline-color: $sv-color--primary;
    }
}

// error
.sv-form-item.-error {
    .sv-form-item__input-wrapper.-type-textarea:before {
        display: block;
        top: 16px;
        right: 16px;
    }

    .sv-textarea {
        outline-color: $carbon--red-60;
        padding-right: 40px;
    }
}

// disabled
.sv-form-item.-disabled {
    .sv-textarea:disabled {
        cursor: not-allowed;
        outline: none;
        background-color: $carbon--gray-10;
        border-bottom: 1px solid transparent;
    }
}

/*------------------------------------
  NUMBER
//------------------------------------*/

sv-number-input {
    .bx--number {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .bx--number__input {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.16px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        box-sizing: border-box;
        display: inline-flex;
        width: 100%;
        min-width: 150px;
        padding-left: 16px;
        padding-right: 32px;
        height: 40px;
        color: $sv-color--text-1;
        background-color: $carbon--gray-10;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $carbon--gray-50;
        transition: background-color $sv-transition-flash-time $sv-transition-effect-common,
        outline $sv-transition-flash-time $sv-transition-effect-common;

        &:focus {
            outline: 2px solid $sv-color--primary;
            outline-offset: -2px;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $carbon--gray-10;
            color: $carbon--disabled-2;
            border-bottom-color: $carbon--gray-10;
        }

        &:disabled ~ .bx--number__controls {
            cursor: not-allowed;
            pointer-events: none;
        }

        &:disabled ~ .bx--number__controls {
            color: $carbon--disabled-2;
        }

        appearance: textfield; // Firefox: Hide spinner (up and down buttons)

        &::-ms-clear {
            display: none; // IE: Hide "clear-field" `x` button on input field
        }

        &::-webkit-inner-spin-button {
            appearance: none; // Safari: Hide number spinner
        }
    }

    .bx--number__controls {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bx--number__control-btn {
        background: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 20px;
        color: inherit;

        span {
            font-size: 8px;
            height: 8px;
            width: 8px;
            line-height: 8px;
            position: relative;
        }

        span:before {
            display: block;
        }

        &.up-icon span {
            top: 5px;
        }

        &.down-icon span {
            top: -5px;
        }

        &:focus {
            outline: 2px solid $sv-color--primary;
            outline-offset: -2px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

// error
.sv-form-item.-error {
    .sv-form-item__input-wrapper.-type-number :before {
        display: block;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 32px;
        z-index: 10;
    }

    .sv-form-item__input .bx--number__input {
        outline-color: $carbon--red-60;
    }
}

/*------------------------------------
  TOGGLE
//------------------------------------*/

.sv-input-switch {
    display: inline-block;

    .ui-inputswitch {
        width: 48px;
        height: 24px;
        display: block;
    }

    .ui-inputswitch-slider.ui-inputswitch-slider {
        border-radius: 15px;
        will-change: box-shadow;
        box-shadow: 0 0 0 1px transparent, 0 0 0 3px transparent;
        transition: all $sv-transition-flash-time $sv-transition-effect-common;
    }

    .ui-inputswitch-slider.ui-inputswitch-slider,
    .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
        background-color: $carbon--gray-50;
    }

    .ui-inputswitch-slider.ui-inputswitch-slider:before {
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        @include easing-animation-flash(transform);
    }

    /**
     * On
     */

    .ui-inputswitch-checked:not(.ui-state-disabled) .ui-inputswitch-slider.ui-inputswitch-slider,
    .ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider.ui-inputswitch-slider {
        background-color: $carbon--green-50;
    }

    .ui-inputswitch-checked .ui-inputswitch-slider.ui-inputswitch-slider:before {
        @include transform(translateX(24px));
    }

    /**
     * Style mimi
     */

    &.-style-small {
        .ui-inputswitch {
            height: 16px;
            width: 32px;
        }

        .ui-inputswitch-slider.ui-inputswitch-slider:before {
            width: 10px;
            height: 10px;
        }

        .ui-inputswitch-slider:after {
            content: "\e025";
            font-family: "carbon" !important;
            top: 3px;
            left: 3px;
            font-size: 6px;
            color: transparent;
            position: absolute;
            width: 10px;
            text-align: center;
            line-height: 10px;
            display: block;
            @include easing-animation-flash(transform);
        }

        .ui-inputswitch-checked .ui-inputswitch-slider:after {
            color: $carbon--green-50;
        }

        .ui-inputswitch-checked .ui-inputswitch-slider.ui-inputswitch-slider:before,
        .ui-inputswitch-checked .ui-inputswitch-slider:after {
            @include transform(translateX(17px));
        }
    }

    /**
     * Focus
     */

    .ui-inputswitch-focus {
        .ui-inputswitch-slider.ui-inputswitch-slider {
            box-shadow: 0 0 0 1px #fff, 0 0 0 3px $sv-color--primary;
        }
    }

    /**
     * Disabled
     */

    .ui-inputswitch.ui-state-disabled {
        opacity: 1;
    }

    .ui-state-disabled .ui-inputswitch-slider.ui-inputswitch-slider {
        cursor: not-allowed;
        background-color: $carbon--disabled-1;

        &:before {
            background-color: $carbon--disabled-2;
        }
    }

    //small style
    &.-style-small {
        .ui-state-disabled .ui-inputswitch-slider:after {
            color: transparent;
        }
    }
}

/*------------------------------------
  Slider
//------------------------------------*/

.sv-f-slider {
    .ui-slider {
        background-color: $carbon--gray-20;
        border-radius: 0;
    }

    .ui-slider-range.ui-slider-range {
        background-color: $carbon--gray-100;
    }

    .ui-slider-handle.ui-slider-handle {
        height: 14px;
        width: 14px;
        box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 2px transparent;
        margin: 0;
        background-color: $carbon--green-100;
        transform: translate(-50%, 50%);
        transition: transform $sv-transition-short-time $sv-transition-effect-common, background $sv-transition-short-time $sv-transition-effect-common, box-shadow $sv-transition-short-time $sv-transition-effect-common;
        outline: none;
        z-index: 3;

        &:focus, &:hover {
            transform: translate(-50%, 50%) scale(1.42857);
        }

        &:focus {
            box-shadow: inset 0 0 0 2px $sv-color--primary, inset 0 0 0 3px $carbon--gray-10;
            background-color: $sv-color--primary;
        }
    }

    /*4x*/
    .ui-slider-handle.ui-slider-handle.ui-slider-handle.ui-slider-handle {
        border: 0;
    }

    // horizontal
    .ui-slider-horizontal.ui-slider-horizontal {
        height: 2px;

        .ui-slider-handle {
            top: auto;
            bottom: 1px;
            margin-top: 0;
        }
    }

    // vertical
    .ui-slider-vertical.ui-slider-vertical {
        width: 2px;

        .ui-slider-handle {
            left: 1px;
            margin-left: 0;
        }
    }

    // disabled
    .ui-state-disabled {
        cursor: not-allowed !important;
        opacity: 1;

        .ui-slider-handle, .ui-slider-range {
            cursor: inherit;
            background-color: $carbon--gray-20;
        }

        .ui-slider-handle:focus, .ui-slider-handle:hover {
            transform: translate(-50%, 50%);
        }

        .ui-slider-handle:focus {
            box-shadow: none;
            background-color: $carbon--gray-20;
        }
    }
}

.sv-f-slider-range-label {
    line-height: 20px;
    letter-spacing: 0.32px;

    &:last-of-type {
        margin-right: 16px;
    }
}

.sv-f-slider-input {
    width: 64px;
    text-align: center;
}

.sv-f-slider-container {
    display: flex;
    align-items: center;
    user-select: none;

    .sv-f-slider {
        display: block;
        flex: 1 1;
        margin: 0 16px;
    }
}

// disabled
.sv-form-item.-disabled {
    .sv-f-slider-range-label {
        color: $carbon--disabled-2;
    }
}

/*------------------------------------
  CHECKBOX
//------------------------------------*/

.sv-checkbox {
    display: flex;
    align-items: center;
    line-height: 20px;
    min-height: 20px;

    .pi {
        font-family: "carbon" !important;
    }

    .pi-times:before {
        content: "";
        position: absolute;
        display: block;
        font-weight: bold;
        width: 8px;
        border-bottom: 2px solid;
        left: 3px;
        top: 6px;
    }

    .pi-check:before {
        content: "";
        position: absolute;
        display: block;
        width: 9px;
        height: 5px;
        left: 3px;
        top: 3px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
    }

    .ui-chkbox {
        height: 16px;
        width: 16px;
        min-width: 16px;
    }

    /*6x*/
    .ui-chkbox-box.ui-chkbox-box.ui-chkbox-box.ui-chkbox-box.ui-chkbox-box.ui-chkbox-box {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .ui-chkbox-icon {
        display: block;
        line-height: 14px;
        width: 100%;
        height: 100%;
        color: #fff;
        border: 1px solid $sv-color--text-1;
    }

    .ui-chkbox-box.ui-state-active .ui-chkbox-icon {
        background-color: $sv-color--text-1;
    }

    .ui-chkbox-label {
        margin-left: 8px;
        cursor: pointer;
    }

    &.-primary-theme {
        .ui-chkbox-icon {
            border-color: $sv-color--primary;
        }

        .ui-chkbox-box.ui-state-active .ui-chkbox-icon {
            background-color: $sv-color--primary;
        }
    }

    // disabled
    .ui-chkbox-box.ui-state-disabled {
        opacity: 1;

        .ui-chkbox-icon {
            cursor: not-allowed;
            border-color: $carbon--disabled-2;
        }

        &.ui-state-active .ui-chkbox-icon {
            background-color: $carbon--disabled-2;
        }
    }

    .ui-chkbox-label.ui-label-disabled {
        color: $carbon--disabled-2;
        cursor: not-allowed;
    }
}

/*------------------------------------
  RADIO
//------------------------------------*/

.sv-radio-button {
    display: flex;
    align-items: center;
    line-height: 20px;
    min-height: 20px;

    .ui-radiobutton {
        height: 18px;
        width: 18px;
        min-width: 18px;
    }

    /*6x*/
    .ui-radiobutton-box.ui-radiobutton-box.ui-radiobutton-box.ui-radiobutton-box.ui-radiobutton-box.ui-radiobutton-box {
        width: 100%;
        height: 100%;
        transition: none;
        background-color: transparent;
        box-shadow: none;
        border: 0;
    }

    .ui-radiobutton-box.ui-radiobutton-box .ui-radiobutton-icon {
        position: static;
        width: 100%;
        height: 100%;
        border: 1px solid $sv-color--text-1;
        margin-left: 0;
        margin-top: 0;
        transition: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            opacity: 0;
            background-color: $sv-color--text-1;
            border-radius: 50%;
        }
    }

    .ui-radiobutton-box.ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
        background-color: transparent;

        &:after {
            opacity: 1;
        }
    }

    .ui-radiobutton-label {
        margin-left: 8px;
    }

    &.-primary-theme {
        .ui-radiobutton-box.ui-radiobutton-box .ui-radiobutton-icon {
            border-color: $sv-color--primary;

            &:after {
                background-color: $sv-color--primary;
            }
        }
    }

    // disabled
    .ui-radiobutton-box.ui-state-disabled {
        opacity: 1;
    }

    .ui-radiobutton-box.ui-radiobutton-box.ui-state-disabled .ui-radiobutton-icon {
        cursor: not-allowed;
        border-color: $carbon--disabled-2;

        &:after {
            background-color: $carbon--disabled-2;
        }
    }

    .ui-radiobutton-label.ui-label-disabled {
        color: $carbon--disabled-2;
        cursor: not-allowed;
    }
}

/*------------------------------------
  DATE PICKER
//------------------------------------*/

.sv-datepicker {
    display: block;

    .ui-calendar {
        display: block;
    }

    .ui-inputtext {
        width: 100%;
        border-radius: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .32px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        display: block;
        position: relative;
        height: 40px;
        padding: 0 16px;
        background-color: $carbon--gray-10;
        color: $sv-color--text-1;
        border: none;
        border-bottom: 1px solid $carbon--gray-50;
        transition: all $sv-transition-short-time $sv-transition-effect-common;
    }

    .ui-inputtext:not(.ui-state-error):enabled {
        &:hover {
            border-color: $carbon--gray-50;
        }

        &:focus {
            outline: 2px solid $sv-color--primary;
            outline-offset: -2px;
        }
    }

    .ui-calendar-w-btn .ui-inputtext {
        padding-right: 40px;
    }

    .ui-datepicker-trigger, .ui-button-icon-left {
        border: 0;
        position: static;
        height: 0;
        display: block;
    }

    .ui-datepicker-trigger:enabled:focus {
        box-shadow: none;
    }

    .ui-button-text {
        display: none;
    }

    .ui-button-icon-left:before {
        position: absolute;
        font-size: 16px;
        height: 16px;
        width: 16px;
        line-height: 16px;
        top: 13px;
        right: 16px;
        color: $sv-color--text-1;
    }

    // disabled

    .ui-inputtext:disabled {
        opacity: 1;
        cursor: not-allowed;
        outline: none;
        background-color: $carbon--gray-10;
        border-bottom: 1px solid transparent;
        color: $carbon--disabled-2;
    }

    .ui-datepicker-trigger.ui-state-disabled {
        opacity: 1;

        .ui-button-icon-left:before {
            color: $carbon--disabled-2;
        }
    }
}

.sv-datepicker-panel.ui-datepicker {
    background-color: $carbon--gray-10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 8px;
    border-radius: 0;

    &, &:not(.ui-datepicker-inline) {
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .1);
        border: none;
    }

    &:focus {
        outline: 1px solid $sv-color--primary;
    }

    .pi {
        font-family: "carbon" !important;
    }

    .pi-chevron-left:before {
        content: "\e034";
    }

    .pi-chevron-right:before {
        content: "\e038";
    }

    .pi-chevron-up:before {
        content: "\e03f";
    }

    .pi-chevron-down:before {
        content: "\e031";
    }

    .ui-datepicker-group {
        width: 100%;
    }

    // header

    .ui-datepicker-header {
        background-color: transparent;
        padding: 0;
        color: $sv-color--text-1;
        position: relative;
    }

    .ui-datepicker-prev.ui-datepicker-prev,
    .ui-datepicker-next.ui-datepicker-next {
        top: 0;
        width: 40px;
        height: 40px;
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        @include easing-animation-flash(background-color);
        border-radius: 0;
    }

    &:not(.ui-state-disabled) .ui-datepicker-next.ui-datepicker-next:hover,
    &:not(.ui-state-disabled) .ui-datepicker-prev.ui-datepicker-prev:hover {
        color: inherit;
        background-color: $carbon--hover-ui;
    }

    span.ui-datepicker-next-icon,
    span.ui-datepicker-prev-icon {
        margin: 0;
        position: static;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 16px;

        &:before {
            display: block;
        }
    }

    .ui-datepicker-title {
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-bottom: 4px;

        > span {
            margin-right: 4px;
            margin-left: 4px;
        }
    }

    // calendar table

    .ui-datepicker-calendar {
        margin-top: 0;
    }

    th, td {
        padding: 0;
    }

    td {
        border-bottom: 1px solid $carbon--gray-10;
    }

    th span,
    td > a, td > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        padding: 0;
    }

    th span {
        font-weight: 500;
    }

    td > a, td > span {
        color: $sv-color--text-1;
        border-radius: 0;
    }

    td > a {
        @include easing-animation-flash();
    }

    td.ui-datepicker-other-month > a,
    td.ui-datepicker-other-month > span {
        opacity: 0.7;
    }

    td.ui-datepicker-today > a,
    td.ui-datepicker-today > span {
        background-color: transparent;
        position: relative;
        color: $sv-color--primary;
        font-weight: 500;

        &::after {
            content: "";
            position: absolute;
            display: block;
            bottom: 7px;
            left: 50%;
            transform: translateX(-50%);
            height: 4px;
            width: 4px;
            background-color: $sv-color--primary;
        }
    }

    td > a.ui-state-active,
    td > span.ui-state-active {
        color: #fff;
        background-color: $sv-color--primary;
    }

    &.is-range-mode {
        td > .ui-state-active.ui-state-active {
            opacity: 1;
            color: $sv-color--text-1;
            background-color: $sv-datepicker-range-date;

            &.is-range-start-date,
            &.is-range-end-date {
                color: #fff;
                background-color: $sv-color--primary;
            }
        }
    }

    &:not(.ui-state-disabled) td a:not(.ui-state-active):not(.ui-state-highlight):hover,
    &:not(.ui-state-disabled) td.ui-datepicker-today a:not(.ui-state-active):hover {
        background-color: $carbon--hover-ui;
    }

    &:not(.ui-state-disabled) td > a:focus {
        outline: 2px solid $sv-color--primary;
        outline-offset: -2px;
    }
}

/*
 * Input wrapper (only in custom element)
 */

.sv-datepicker__input-container {
    display: flex;
}

.sv-datepicker__input-wrapper {
    flex: 1 1;
    position: relative;

    & + & {
        margin-left: 2px;
    }
}

/*
 * Year input (only in custom element)
 */

.sv-datepicker-year__input-wrapper {
    width: 60px;
    position: relative;
}

.sv-datepicker-year__input {
    font-weight: 700;
    background-color: $carbon--gray-10;
    border: 0;
    padding: 4px;
    width: 100%;
    color: $sv-color--text-1;
    cursor: default;

    &:focus {
        outline: 1px solid $sv-color--primary;
    }
}

.sv-datepicker-year__arrow-up,
.sv-datepicker-year__arrow-down {
    right: 4px;
    position: absolute;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    display: block;

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 33%;
    }
}

.sv-datepicker-year__input-wrapper:hover {
    .sv-datepicker-year__arrow-up,
    .sv-datepicker-year__arrow-down {
        opacity: 1;
    }
}

.sv-datepicker-year__arrow-up {
    top: 3px;

    &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid $sv-color--text-1;
    }

    &:hover:after {
        border-bottom-color: $sv-color--primary;
    }
}

.sv-datepicker-year__arrow-down {
    top: 10px;

    &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $sv-color--text-1;
    }

    &:hover:after {
        border-bottom-color: $sv-color--primary;
    }
}

/*
 * error
 */

.sv-form-item.-error {
    .ui-inputtext.ui-inputtext.ui-inputtext {
        outline-color: $carbon--red-60;
    }
}

/*------------------------------------
  DROPDOWN
//------------------------------------*/

.sv-dropdown {
    display: block;

    .ui-dropdown {
        min-width: 0;
        border: 0;
        border-radius: 0;
        display: block;
        position: relative;
        background-color: $carbon--gray-10;
        border-bottom: 1px solid $carbon--gray-50;
        width: 100%;
        height: 40px;
        color: $sv-color--text-1;
        transition: background-color $sv-transition-flash-time $sv-transition-effect-common;
    }

    .ui-dropdown.ui-dropdown.ui-dropdown {
        outline-offset: -2px;
        outline: 2px solid transparent;
    }

    &.light-theme {
        .ui-dropdown {
            background-color: #fff;
        }
    }

    .ui-dropdown-label.ui-dropdown-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: .16px;
        display: block;
        height: 40px;
        padding: 12px 42px 12px 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        color: inherit;

        &.ui-placeholder {
            color: $carbon--gray-50;
        }
    }

    .ui-dropdown-trigger.ui-dropdown-trigger,
    .ui-dropdown-trigger-icon.ui-dropdown-trigger-icon.ui-dropdown-trigger-icon {
        color: inherit;
        position: static;
        height: 0;
        padding: 0;
    }

    .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label.ui-dropdown-label {
        padding-right: 72px;
    }

    .ui-dropdown-clear-icon.ui-dropdown-clear-icon {
        color: inherit;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        margin-top: -15px;
        width: 30px;
        right: 33px;
    }

    /*3x*/
    .ui-dropdown-trigger-icon.ui-dropdown-trigger-icon.ui-dropdown-trigger-icon:before {
        position: absolute;
        font-size: 16px;
        height: 40px;
        width: 16px;
        line-height: 40px;
        right: 16px;
        top: 0;
        transition: transform $sv-transition-short-time $sv-transition-effect-common;
        transform-origin: 50% 50%;
    }

    .ui-dropdown:not(.ui-state-disabled):hover {
        background-color: $carbon--hover-ui;
        border-color: $carbon--gray-50;
    }

    .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
        outline: 2px solid $sv-color--primary;
        outline-offset: -2px;
    }

    .ui-dropdown.ui-dropdown-open {
        .ui-dropdown-trigger-icon.ui-dropdown-trigger-icon:before {
            transform: rotate(-180deg);
        }

        &:not(.ui-state-disabled):hover {
            background-color: $carbon--gray-10;
        }

        //&:not(.ui-state-disabled).ui-state-focus {
        //    outline: 0;
        //    border-bottom-color: $carbon--gray-20;
        //}
    }

    // disabled

    .ui-dropdown.ui-state-disabled {
        opacity: 1;
        color: $carbon--disabled-2;
        border-bottom-color: transparent;

        .ui-dropdown-label.ui-dropdown-label.ui-placeholder {
            color: inherit;
        }

        .ui-dropdown-trigger, .ui-dropdown-label {
            cursor: not-allowed;
        }
    }
}

.sv-form-item.-error {
    .sv-form-item__input-wrapper.-type-dropdown:before {
        display: block;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 40px;
        z-index: 10;
    }

    .ui-dropdown.ui-dropdown.ui-dropdown {
        outline-color: $carbon--red-60;
    }

    .ui-dropdown-label.ui-dropdown-label.ui-dropdown-label {
        padding-right: 56px;
    }

    .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label.ui-dropdown-label {
        padding-right: 88px;
    }

    .ui-dropdown.ui-dropdown-clearable .ui-dropdown-clear-icon.ui-dropdown-clear-icon {
        right: 58px;
    }
}

/*
 * Panel
 */

.ui-dropdown-panel.ui-dropdown-panel {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1);
    background-color: $carbon--gray-10;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border: 0;
    max-width: 480px;

    .ui-dropdown-item.ui-dropdown-item {
        padding: 0;

        &.ui-state-highlight {
            background-color: $carbon--selected-ui;
        }
    }

    .ui-dropdown-item.ui-dropdown-item > span {
        outline: 2px solid transparent;
        outline-offset: -2px;
        display: block;
        color: $sv-color--text-common;
        font-weight: 400;
        line-height: 16px;
        padding: 11px 0;
        margin: 0 16px;
        border: 1px solid transparent;
        border-top-color: $carbon--gray-20;

        &:hover {
            color: $sv-color--text-1;
        }
    }

    .ui-dropdown-item.ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled) {
        &:hover {
            background-color: $carbon--hover-ui;
        }

        &:active {
            background-color: $carbon--selected-ui;
        }
    }
}
